import { Modal } from "react-bootstrap";
import { pastOrder } from "./PastOrderData";
import { useSelector } from "react-redux";
import Cart from "../cart/Cart";

 
const PastOrders = (props) => {
    const { show } = props;
    const { orderHeader } = useSelector((state) => ({orderHeader: state.orderHeader}));

    const orderAllHandler = (items) => {
        for (let i = 0; i < items.order.length; i++) {
            orderOneHandler(items.order[i]);
        }
    }

    
    const orderOneHandler = (item) => {
        orderHeader.addOrderDetails(item.menuItem, item.modifiers, item.quantity, item.paidQuantity, item.price, item.modifierPrice, item.totalPrice, item.concept, item.stations)
    }
    

    const goCart = () => {
        props.setShow(false);
        props.setAccountShow(false);
    }


    return (
        <Modal show={show} className="loginModal" onHide={props.setShow(false)}>
            <Modal.Header>
                Previous Orders
            </Modal.Header>
            <div style={{overflow:"scroll", height:"80%"}}>
                {
                    pastOrder.map((child, index) => {
                        return (
                            <div key={index}>
                                <div className="orderedOn" key={index} style={{display: "flex", justifyContent: "space-between", padding: "50px 10px 10px 20px", borderBottom: "1px solid #DEE2E6"}}>
                                    Ordered On: {child.orderDate}
                                    <div style={{color:"#112f82", cursor: "pointer"}} onClick={() => orderAllHandler(child)}>Add all</div>
                                </div>
                                {child.order.map((item, index) => {
                                    return(
                                        <div key={index}>
                                            <div className="cartItemDiv" key={index} style={{alignItems: "center"}}>
                                                <div className="cartItemImgDiv" style={{height: "60px"}}>
                                                    <div><img style={{cursor: "unset"}} src={item.image === undefined ? item.image : item.image} alt="" /></div>
                                                </div>
                                                <div className="cartItem">
                                                    <div className="cartItemHeader">
                                                        <div className="cartItemName" style={{cursor: "unset"}}>{item.description}</div>
                                                        <div className="cartPrice">${item.price.toFixed(2)} x {item.quantity}</div>
                                                    </div>
                                                    <div className="cartItemModsDiv">
                                                        {item.modifiers !== undefined &&
                                                            // eslint-disable-next-line array-callback-return
                                                            item.modifiers.map((modifier, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <div className="cartItemMod" key={index}>
                                                                            <div>
                                                                                {modifier.mod.modifierName.toUpperCase()}
                                                                                {modifier.quantity > 1 &&
                                                                                    <>{" "}x{modifier.quantity}</>
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                {modifier.mod.amount > 0 &&
                                                                                    <>
                                                                                        {modifier.quantity === 1 &&
                                                                                            <div>(+${modifier.mod.amount.toFixed(2)})</div>
                                                                                        }
                                                                                        {modifier.quantity > 1 &&
                                                                                            <div>(+${(modifier.mod.amount * modifier.quantity).toFixed(2)})</div>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <svg style={{margin: "0 10px 8px 10px", cursor: "pointer"}} onClick={() => orderOneHandler(item)} xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                </svg>
                                            </div>
                                            {index < child.order.length-1 && <hr style={{margin:"0px", width:"90%", marginLeft:"5%"}}/>}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })
                }
            </div>
            <div onClick={() => goCart} style={{bottom:"5%", width:"100%", display:"flex", justifyContent: "space-evenly"}}>
                <Cart cartMobile={false} pastOrders={true} changePage={() => console.log()} style={{marginBottom: "24px"}} />
            </div>
        </Modal>
    )
}


export default PastOrders;