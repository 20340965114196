import React from 'react';


const Marker = () => {
    const MarkerStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: "3em", 
        width: "3em"
      }


    return (
        <div style={MarkerStyle}>
            <svg viewBox="0 0 24 24" style={{transform: "translate(0, -1.1em)"}}>
                <path className="marker" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
            </svg>
            
        </div>
    )
}


export default Marker;