import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import { useEffect, useState } from 'react';
import axios from "axios";


const GoogleMap = (props) => {
    const { address } = props;
    const apiKey = "AIzaSyCJ3bHFGArvnog0SQzO08H-Cbx_kRhqi0c";
    const [center, setCenter] = useState({ lat: 27.336411, lng: -82.544228 })
    const [markers, setMarkers] = useState([
      { lat: 27.336411, lng: -82.544228 }, // KOJO RESTAURANT
      { lat: 27.336411, lng: -82.544228 }
      ]
    );
    
    const [polyline, setPolyline] = useState();
    const [mapObjects, setMapObjects] = useState([])

    const MapLoaded = (map, maps) => {
      setMapObjects([map, maps]);
      var tempPolyline = new maps.Polyline({
        path: markers,
        geodesic: true,
        strokeColor: '#00a1e1',
        strokeOpacity: 1.0,
        strokeWeight: 4
      }) 
      setPolyline(tempPolyline)
      tempPolyline.setMap(map)
    }

    useEffect(() => {
      if (address !== "") {
        axios.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + address.formatted_address + "&key=" + apiKey)
          .then(
            (response) => {
              setMarkers([markers[0], response.data.results[0].geometry.location]);
            }
          )
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address])
      

    useEffect(() => {
      if (markers[1].lat !== markers[0].lat) {
        var tempPolyline = polyline;
        tempPolyline.setPath(markers)
        setPolyline(tempPolyline)
        
        setCenter({lat: ((markers[0].lat + markers[1].lat) / 2), lng: ((markers[0].lng + markers[1].lng) / 2)});

        let mapBounds = new mapObjects[1].LatLngBounds();
        markers.forEach((marker)=>{mapBounds.extend(marker)});
        mapObjects[0].fitBounds(mapBounds)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markers])


    return(
        <div style={{height: "300px", width: "100%", borderRadius: "5px", overflow: "hidden"}}>
            <GoogleMapReact 
                bootstrapURLKeys={{key: apiKey}}
                center={center}
                zoom={15}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({map, maps}) => MapLoaded(map, maps)}
            >
              <Marker
                lat={markers[0].lat}
                lng={markers[0].lng}
              />
              {markers[1].lat !== 0 &&
                <Marker
                lat={markers[1].lat}
                lng={markers[1].lng}
                />
              }
            </GoogleMapReact>
        </div>
    )
}


export default GoogleMap;