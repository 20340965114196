import QuickResponse from "../../pages/quickResponse/QuickResponse";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";
import { ActionTypes } from "../../redux/constants";
import GetMenuId from "../menuManagement/getMenuId/GetMenuId";
import OrderHeader from '../../models/OrderHeader';
import Loader from "./Loader";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

//import '../../Custom-KluckKafe.css';
//import '../../Custom-Kojo.css';
//import '../../Custom-SoFresh.css';

 
const Loading = (props) => {
    const { oo } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [cssPath, setCssPath] = useState();
    const { qrcode } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { clientId } = useSelector((state) => ({clientId: state.clientId}));
    
    useEffect(() => {
      if (clientId === "") getData();
      else setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = () => {
        axios.get("/unsecure/quickresponse/" + qrcode)
            .then((response) => {
                let locationData = response.data;
                if (window.location.href.includes("/oo/")) {
                    const cart = new OrderHeader();
                    cart.locationId = locationData.locationId;
                    cart.clientId = locationData.clientId;
                    dispatch({type: ActionTypes.CREATE_CART, payload: {cart: cart}});
                }
                
                if (locationData.quickResponseConcepts.length === 0) navigate("/error/noconcepts");
                else {
                    dispatch({type: ActionTypes.ADD_CLIENT_ID, payload: {clientId: locationData.clientId}});
                    //getCss(locationData.clientId);
                    getIcon(locationData.clientId);
                    document.title = locationData.description;
                    axios.get("/unsecure/menuSchedules/" + locationData.locationId)
                        .then((response) => {
                            let menuSchedules = response.data;
                            locationData.quickResponseConcepts.forEach(concept => {
                                axios.get("/unsecure/menus/concept/" + concept.concept.conceptId)
                                    .then((response) => {
                                        let data = response.data;
                                        let menus = data.filter(menu => menu.guestFacing);
                                        if (menus.length > 0)  getMenu(menus, menuSchedules, concept.concept, locationData.locationId);
                                    })
                                    .catch((error) => console.log("ERROR:" + error));
                            });
                        })
                        .catch((error) => console.log("ERROR:" + error));
                }
            })
            .catch((error) => console.log("ERROR:" + error));
    }

    /*
    const getCss = (clientId) => {
        let head = document.getElementsByTagName('HEAD')[0];
        let link = document.createElement('link');
        link.rel = 'stylesheet'; 
        link.type = 'text/css';
        link.href = "https://kluck.s3.amazonaws.com/" + clientId.toUpperCase() + "/CSS/web.css"; 
        head.appendChild(link);
    }
    */
    const getIcon = (clientId) => {
        let head = document.getElementsByTagName('HEAD')[0];
        let link = document.createElement('link');
        link.rel = 'icon'; 
        link.href = "https://kluck.s3.amazonaws.com/" + clientId.toUpperCase() + "/favicon-32x32.png";
        head.appendChild(link);
    }

    const getMenu = (menus, menuSchedules, concept, locationId) => {
        let tempArray = [];
        for (let i = 0; i < menus.length; i++) {
            for (let k = 0; k < menuSchedules.length; k++) {
                if (menus[i].menuId === menuSchedules[k].menuId) tempArray.push(menuSchedules[k]);
            }
        }

        let menuId;
        if (tempArray.length > 0) menuId = GetMenuId(tempArray).menuId;
        else menuId = menus[0].menuId;
        if (menuId !== "") {
            axios.get("/unsecure/menus/" + menuId + "/" + locationId)
                .then((response) => {
                    let data = response.data;
                    dispatch({type: ActionTypes.ADD_CONCEPT, payload: {conceptId: concept.conceptId, conceptName: concept.conceptName, menus: data, thumbNailUrl: concept.thumbNailUrl, logoUrl: concept.logoUrl}});
                    axios.get("/unsecure/menuItemLocations/location/" + locationId)
                        .then((response) => {
                            let data = response.data
                            dispatch({type: ActionTypes.ADD_86, payload: {data: data}});
                        })
                    var tempMods;
                    axios.get("/unsecure/modifierGroups/concept/" + concept.conceptId)
                        .then((response) => {
                            let data = response.data
                            tempMods = data;
                            axios.get("/unsecure/modifierGroupConnector/concept/" + concept.conceptId)
                                .then((response) => {
                                    let data = response.data
                                    dispatch({type: ActionTypes.ADD_MODIFICATION_GROUP, payload: {modifiers: tempMods, connectors: data}});
                                    setTimeout(() => {setIsLoading(false)}, 500);
                                })
                                .catch((error) => console.log("ERROR:" + error));
                        })
                        .catch((error) => console.log("ERROR:" + error));
                })
                .catch((error) => console.log("ERROR:" + error));
        }
    }

    useEffect(() => {
      if (window.location.href.includes("aaxab")) setCssPath("/Custom-Kojo.css");
      if (window.location.href.includes("baxab")) setCssPath("/Custom-KluckKafe.css");
      if (window.location.href.includes("caxab")) setCssPath("/Custom-SoFresh.css");
    }, [])


    return (
        <>
            <QuickResponse oo={oo} />
            {isLoading &&
                <Loader />
            }
            <link rel="stylesheet" type="text/css" href={cssPath} />
            <style>@import url("https://fonts.googleapis.com/css?family=Inter")</style>
            <style>@import url("https://fonts.googleapis.com/css?family=Roboto")</style>
            <style>@import url("https://fonts.googleapis.com/css?family=Sanchez")</style>
            <style>@import url("https://fonts.googleapis.com/css?family=Open+Sans")</style>
        </>
    )
}


export default Loading;