import { useState, useEffect } from "react";
import { Form, ToggleButton } from 'react-bootstrap';
import './Review.css';
import '../../App.css';
import axios from "axios";
import { useSelector } from "react-redux";
import Error from "../error/Error";
import positive from '../../assets/positive.svg';
import negative from '../../assets/negative.svg';
import {motion, LayoutGroup} from 'framer-motion/dist/framer-motion'
const Review = () => {
    const [reviewType, setReviewType] = useState("");
    const [checkValues, setCheckValues] = useState([]);
    const params = JSON.parse('{"' + decodeURI(window.location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
    const [submitted, setSubmitted] = useState(false);
    const [failed, setFailed] = useState(false);
    const [errorCode, setErrorCode] = useState()
    const [textboxValue, setTextboxValue] = useState("");
    const { receiptData } = useSelector((state) => ({receiptData: state.receiptData}));
    const animationVars = {
        selected: {scale: 1.5},
        notSelected:{scale: 1}
    }
    const data = {
        positive: [
            {name: "Wait Time", boolean: false},
            {name: "Quality", boolean: false},
            {name: "Enviroment", boolean: false},
            {name: "Customer Service", boolean: false}
        ],
        negative: [
            {name: "Wait Time", boolean: false},
            {name: "Quality", boolean: false},
            {name: "Enviroment", boolean: false},
            {name: "Customer Service", boolean: false},
            {name: "Undelivered Goods or Service", boolean: false},
            {name: "Unknown Charge", boolean: false}
        ]
    }

    useEffect(() => {
        setReviewType(params.sentiment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        let tempArray = [];
        if (reviewType === "positive") data.positive.forEach(element => tempArray.push(element));
        else if (reviewType === "negative") data.negative.forEach(element => tempArray.push(element));
        setCheckValues(tempArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewType])

    let counter = 1;

    const counterCheck = (index) => {
        if (counter === 3) {
            counter = 1;
            onClickHandler(index);
        } else counter += 1;
    }

    const onClickHandler = (index) => {
        let tempArray = [];
        checkValues.forEach((element, index1) => {
            if (index1 === index) tempArray.push({name: element.name, boolean: !element.boolean});
            else tempArray.push(element);
        });
        setCheckValues(tempArray);
    }
    console.log(reviewType, data.positive)
    const submitHandler = () => {
        let tempString = reviewType + " - ";
        checkValues.forEach((element, index) => {
            console.log(element,index,data.positive[0],checkValues.length)
            if (reviewType === "positive" && element.boolean) tempString += data.positive[index].name + ' , ';
            else if (reviewType === "negative" && element.boolean) tempString += data.negative[index].name + ' , ';
        });
        if (textboxValue !== "") console.log(textboxValue);
        const Emaildata = {
            "from": "info@getkluck.com",
            "friendlyName": "Receipt Review",
            "subject": "A Customer has submitted a review",
            "message": "<h1>A New <a href=kluck.to/r/"+receiptData.linkId+">Check</a> Submitted A Review </h1><br> Sentiment and Reasoning: "+tempString.slice(0, -2)+ "<br> Custom Comment: " + textboxValue
        }
        axios.post("/unsecure/sendContactUs/clients/"+receiptData.clientId+"/"+receiptData.locationId, Emaildata)
            .then((response) => {
                //console.log(response)
                if (response.status === 200) setSubmitted(true);
            }).catch((error) => {
                setFailed(true);
                setErrorCode(error)
            })
    }
    
    return (
        <>
            {reviewType !== "" && checkValues.length > 0 &&
                <div className="tContainer1">
                    {!submitted && !failed &&
                        <>
                            <div className="leftTitle" style={{margin: "62px 24px 0", lineHeight: "48px", fontSize: "3em"}}>Review</div>
                            <div className="tContainer2">
                                <div className="reviewTypeDiv">
                                    <motion.img variants={animationVars} animate={reviewType === "positive" ? "selected" : "notSelected"} onClick={() => setReviewType("positive")} style={{height: "50px", margin:"25px"}} alt="" src={positive}/>
                                    <motion.img variants={animationVars} animate={reviewType === "negative" ? "selected" : "notSelected"} onClick={() => setReviewType("negative")} style={{height: "50px", margin:"25px"}} alt="" src={negative}/>     
                                </div>
                                {reviewType === "positive" &&
                                    <div style={{textAlign: "center", marginBottom: "12px"}}>
                                        <div>Thaty's great!</div>
                                        <div>Tell us what we got right</div>
                                    </div>
                                }
                                {reviewType === "negative" &&
                                    <div style={{textAlign: "center", marginBottom: "12px"}}>
                                        <div>We're really sorry</div>
                                        <div>Tell us what went wrong</div>
                                    </div>
                                }
                                
                                <div className="tContainer3" style={{width: "425px"}}>
                                <LayoutGroup>
                                    {reviewType === "positive" && checkValues.length === data.positive.length &&
                                        data.positive.map((child, index) => {
                                            return (
                                                <motion.div layout className="reviewOptionDiv" key={index}>
                                                    <ToggleButton
                                                        key={index}
                                                        type="checkbox"
                                                        variant="secondary"
                                                        checked={checkValues[index].boolean}
                                                        onClick={() => counterCheck(index)}
                                                        onChange={() => console.log()}
                                                    >
                                                        <div>
                                                            <Form>
                                                                <Form.Check 
                                                                    type="checkbox" 
                                                                    checked={checkValues[index].boolean} 
                                                                    onClick={() => counterCheck(index)}
                                                                    onChange={() => console.log()}
                                                                />
                                                            </Form>
                                                            <div>{child.name}</div>
                                                        </div> 
                                                    </ToggleButton>
                                                </motion.div>
                                            )
                                        })
                                    }
                                    {reviewType === "negative" && checkValues.length === data.negative.length &&
                                        data.negative.map((child, index) => {
                                            return (
                                                <motion.div layout className="reviewOptionDiv" key={index}>
                                                    <ToggleButton
                                                        key={index}
                                                        type="checkbox"
                                                        variant="secondary"
                                                        checked={checkValues[index].boolean}
                                                        onClick={() => counterCheck(index)}
                                                        onChange={() => console.log()}
                                                    >
                                                        <div>
                                                            <Form>
                                                                <Form.Check 
                                                                    type="checkbox" 
                                                                    checked={checkValues[index].boolean} 
                                                                    onClick={() => counterCheck(index)}
                                                                    onChange={() => console.log()}
                                                                />
                                                            </Form>
                                                            <div>{child.name}</div>
                                                        </div> 
                                                    </ToggleButton>
                                                </motion.div>
                                            )
                                        })
                                    }
                                    <div style={{padding: "0 48px 24px"}}>
                                        <Form.Control 
                                            as="textarea" 
                                            rows={3} 
                                            placeholder="Tell us more..."
                                            value={textboxValue}
                                            onChange={(e) => setTextboxValue(e.target.value)}
                                        />
                                    </div>
                                     </LayoutGroup>
                                </div>
                               
                            </div>
                            <div className="itemModalAddToCart reviewSubmit" onClick={() => submitHandler()}><p>SUBMIT</p></div>
                        </>
                    }
                    {submitted &&
                        <div  style={{display:"flex", height:"90vh", width:"100vw", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            <div>
                                <svg style={{height: "10vw"}} viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M22 5.5H9C7.9 5.5 7 6.4 7 7.5V16.5C7 17.61 7.9 18.5 9 18.5H22C23.11 18.5 24 17.61 24 16.5V7.5C24 6.4 23.11 5.5 22 5.5M22 16.5H9V9.17L15.5 12.5L22 9.17V16.5M15.5 10.81L9 7.5H22L15.5 10.81M5 16.5C5 16.67 5.03 16.83 5.05 17H1C.448 17 0 16.55 0 16S.448 15 1 15H5V16.5M3 7H5.05C5.03 7.17 5 7.33 5 7.5V9H3C2.45 9 2 8.55 2 8S2.45 7 3 7M1 12C1 11.45 1.45 11 2 11H5V13H2C1.45 13 1 12.55 1 12Z" />
                                </svg>
                            </div>
                            <div style={{textAlign: "center", marginBottom: "12px", fontSize:"2vw"}}>
                                {reviewType === "positive" &&
                                    <>We're glad you enjoyed your experience. <br></br> Thank you for your feedback</>
                                }
                                {reviewType === "negative" &&
                                    <>We're sorry that you didn't enjoy your experience <br></br> Thank you for your feedback</>
                                }
                            </div>
                        </div>
                    }
                    {failed &&
                         <div  style={{display:"flex", height:"90vh", width:"100vw", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            <div>
                                <Error code="receipt" errorcode={errorCode}/>
                            </div>
                         </div>
                    }
                </div>
            }
        </>
    )
}


export default Review;