import Section from "../../section/Section";
import { useEffect, useState } from 'react';
import NavBar from "../../navBar/NavBar";
import Footer from '../../Footer/Footer';
import { Row } from 'react-bootstrap';


const Menu = (props) => {
    const { selectedConcepts, inputValue, currentPage, sectionFilter, dietFilter } = props;
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        const tempArray = [];
        const hashMap = [];
        for(var i=0;i < selectedConcepts.length;i++) {
            const menu = selectedConcepts[i].menus;
            const rootSection = menu.section;
            let showImages = false;

            if (window.location.href.includes("/qr/")) showImages = menu.showImageOnQuickResponse;
            else if (window.location.href.includes("/oo/")) showImages = menu.showImageOnOnlineOrdering;
            else if (window.location.href.includes("/di/")) showImages = menu.showImageOnOnlineOrdering;

            for (let j = 0; j < rootSection.children.length; j++){
                if (rootSection.children[j].sectionName === sectionFilter) {
                    for(var k=0;k < rootSection.children[j].children.length;k++) {
                        tempArray.push({guestFacing: rootSection.children[j].children[k].guestFacing, fileLocation: rootSection.children[j].children[k].fileLocation,
                            sectionName: rootSection.children[j].children[k].sectionName, sequence:rootSection.children[j].children[k].sequence,
                            sectionItems: rootSection.children[j].children[k].sectionItems, children:rootSection.children[j].children[k].children, 
                            sectionImageUrl:"", showImages: showImages, conceptName: selectedConcepts[i].conceptName,
                            sectionDescription: rootSection.children[j].children[k].sectionDescription});
                    }
                }
            }
        }

        for (let p = 0; p < tempArray.length; p++) {
            if (!hashMap.some(name => name.sectionName === tempArray[p].sectionName)) {
                const newSectionItems = [...tempArray[p].sectionItems];
                hashMap.push({sequence: tempArray[p].sequence,fileLocation: tempArray[p].fileLocation, guestFacing: tempArray[p].guestFacing,
                    sectionImageUrl: tempArray[p].sectionImageUrl,sectionName: tempArray[p].sectionName, children: tempArray[p].children, 
                    sectionItems: newSectionItems, showImages: tempArray[p].showImages, conceptName: tempArray[p].conceptName,
                    sectionDescription: tempArray[p].sectionDescription})
            } else {
                const array = hashMap.find(element => element.sectionName === tempArray[p].sectionName);
                array.children.push(...tempArray[p].children);
                array.sectionItems.push(...tempArray[p].sectionItems);
            }
        }
        setMenu(hashMap);
    }, [selectedConcepts, sectionFilter])

    const checkInputValue = (section) => {
        let boolean;
        
        const forEach = (section) => {
            section.sectionItems.forEach(menuItem => {
                if (menuItem.menuItem !== null) {
                    if (menuItem.menuItem.menuItemName.toLowerCase().includes(inputValue.toLowerCase()) || menuItem.menuItem.menuItemDescription.toLowerCase().includes(inputValue.toLowerCase())) {
                        if (checkDietFilter(menuItem.menuItem.diets) && boolean !== true) boolean = true;
                    }
                }
            });
            if (boolean !== true) boolean = false;

            if (section.children.length > 0) {
                section.children.forEach(section => {
                    forEach(section);
                });
            }
        }
        forEach(section);
        return boolean;
    }

    const checkDietFilter = (diets) => {
        let filterCount = 0;
        let count = 0;
        
        if (dietFilter.gluten) { filterCount += 1; diets.forEach(diet => {if (diet !== null && diet.dietName === "Gluten Free") count += 1}); }
        if (dietFilter.dairy) { filterCount += 1; diets.forEach(diet => {if (diet !== null && diet.dietName === "Dairy Free") count += 1}); }
        if (dietFilter.vegan) { filterCount += 1; diets.forEach(diet => {if (diet !== null && diet.dietName === "Vegan") count += 1}); }
        if (dietFilter.vegetarian) { filterCount += 1; diets.forEach(diet => {if (diet !== null && diet.dietName === "Vegetarian") count += 1}); }

        if (count === filterCount) return true;
        else return false
    }

    const [showNav, setShowNav] = useState(true);
    
    useEffect(() => {
        setShowNav(true);
        let tempResults = false;
        menu.forEach(section => { if (checkInputValue(section)) tempResults = checkInputValue(section) });
        setShowNav(tempResults);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue, dietFilter, menu])
    

    return (
        <div className="menuContainer">
            {(showNav && menu.length > 0) &&
                <NavBar children={menu} inputValue={inputValue} dietFilter={dietFilter} />
            }
            <div className="sectionContainer" id ="sectionContainer">
                <Row className="section">
                    <Section children={menu} level={1} conceptsLength={selectedConcepts.length} inputValue={inputValue} currentPage={currentPage} dietFilter={dietFilter} />
                    {(!showNav && menu.length > 0) &&
                        <div className="noResultsDiv"><p>No results with the current filters.</p></div>
                    }
                    {(showNav && menu.length > 0) &&
                        <Footer />
                    }
                </Row>
            </div>
        </div>
    )
}


export default Menu;