import Cart from "../../components/cart/Cart";
import Account from "../../components/account/Account";
import Favorites from "../../components/favorites/Favorites";


const BottomBar = (props) => {
    const { currentPage } = props;

    return (
        <div className="bottomBarDiv">
            <div className="bottomBarButton" onClick={() => props.changePage("Home")}>
                <svg viewBox="0 0 24 24">
                    <path 
                        className={currentPage !== "Favorites" && currentPage !== "Account" && currentPage !== "Cart"? "currentPage" : null} 
                        d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" 
                    />
                </svg>
                <p className={currentPage === "Home" ? "currentPage" : null}>Home</p>
            </div>
            <Favorites changePage={props.changePage} currentPage={currentPage} favoritesMobile={true} />
            <Account changePage={props.changePage} currentPage={currentPage} accountMobile={true} />
            <Cart changePage={props.changePage} currentPage={currentPage} cartMobile={true} />
        </div>
    )
}


export default BottomBar;