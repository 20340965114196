export const pastOrder = [
    {
        orderDate: "6/12/2022",
        order: [{
            "image": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
            "concept": "SoFresh",
            "properties": [],
            "orderDetailId": "2a4ad7f0-796e-47bd-9bf4-3fc998f40d1e",
            "orderHeaderId": "58e18512-ee62-4276-8276-b69bb290c8e5",
            "seatNumber": 1,
            "sequence": 1,
            "modifiers": [],
            "menuItemId": "2a4544f1-395b-46d0-a872-d0204957317a",
            "menuItem": {
              "menuItemId": "2a4544f1-395b-46d0-a872-d0204957317a",
              "menuItemName": "kale & apple smoothie",
              "tags": "kale apple smoothie",
              "menuItemDescription": "kale, spinach, apple, banana, lemon ",
              "defaultPrice": 7.49,
              "url": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
              "thumbNailUrl": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
              "conceptId": "31f9f250-3a62-4057-90c6-c245a5abe813",
              "modifierDefaults": [],
              "taxable": true,
              "alcohol": false,
              "guestFacing": true,
              "diets": [
                {
                  "dietId": "a3566a37-d14e-4129-9147-90b2a01f4866",
                  "dietName": "V",
                  "guestFacing": true,
                  "url": null
                },
                {
                  "dietId": "8d670de7-559d-4ff9-bee3-c3e873d4635d",
                  "dietName": "GF",
                  "guestFacing": true,
                  "url": null
                }
              ],
              "menuItemOptions": [],
              "calories": 0,
              "fats": 0,
              "carbohydrates": 0,
              "proteins": 0,
              "sodium": 0
            },
            "description": "kale & apple smoothie",
            "notes": "",
            "quantity": 1,
            "paidQuantity": 7.49,
            "price": 7.49,
            "modifierPrice": 0,
            "compGroupId": "00000000-0000-0000-0000-000000000000",
            "compReasonId": "00000000-0000-0000-0000-000000000000",
            "voidReasonId": "00000000-0000-0000-0000-000000000000",
            "authorizedEmployeeId": "00000000-0000-0000-0000-000000000000",
            "menuItemOptionId": "00000000-0000-0000-0000-000000000000",
            "authorizedTimestamp": "2022-06-17T17:19:02.281Z",
            "compAmount": 0,
            "compNote": "",
            "modifierDescription": "",
            "orderDetailModifiers": [],
            "totalPrice": 7.49,
            "holdTime": "P12D",
            "stations": [
                {
                    "stationId": "0e39f180-83b7-4c43-b96f-190fa9f0d45a",
                    "stationName": "Bar",
                    "showOnHold": false,
                    "clientId": "63692fe5-2541-9c4e-9501-988fa0414208"
                }
            ],
            "orderTimeStamp": "2022-06-17T17:19:02.281Z",
            "completedTimeStamp": "2022-06-17T17:19:02.281Z",
            "hold": false,
            "submited": false,
            "selected": false,
            "status": "A",
            "abbreviation": "kaleapple smoothie",
            "giftCardNumber": "",
            "cvv": "",
            "cookingStatus": "O"
        },
        {
            "image": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
            "concept": "SoFresh",
            "properties": [],
            "orderDetailId": "2a4ad7f0-796e-47bd-9bf4-3fc998f40d1e",
            "orderHeaderId": "58e18512-ee62-4276-8276-b69bb290c8e5",
            "seatNumber": 1,
            "sequence": 1,
            "modifiers": [],
            "menuItemId": "2a4544f1-395b-46d0-a872-d0204957317a",
            "menuItem": {
              "menuItemId": "2a4544f1-395b-46d0-a872-d0204957317a",
              "menuItemName": "kale & apple smoothie",
              "tags": "kale apple smoothie",
              "menuItemDescription": "kale, spinach, apple, banana, lemon ",
              "defaultPrice": 7.49,
              "url": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
              "thumbNailUrl": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
              "conceptId": "31f9f250-3a62-4057-90c6-c245a5abe813",
              "modifierDefaults": [],
              "taxable": true,
              "alcohol": false,
              "guestFacing": true,
              "diets": [
                {
                  "dietId": "a3566a37-d14e-4129-9147-90b2a01f4866",
                  "dietName": "V",
                  "guestFacing": true,
                  "url": null
                },
                {
                  "dietId": "8d670de7-559d-4ff9-bee3-c3e873d4635d",
                  "dietName": "GF",
                  "guestFacing": true,
                  "url": null
                }
              ],
              "menuItemOptions": [],
              "calories": 0,
              "fats": 0,
              "carbohydrates": 0,
              "proteins": 0,
              "sodium": 0
            },
            "description": "kale & apple smoothie",
            "notes": "",
            "quantity": 1,
            "paidQuantity": 7.49,
            "price": 7.49,
            "modifierPrice": 0,
            "compGroupId": "00000000-0000-0000-0000-000000000000",
            "compReasonId": "00000000-0000-0000-0000-000000000000",
            "voidReasonId": "00000000-0000-0000-0000-000000000000",
            "authorizedEmployeeId": "00000000-0000-0000-0000-000000000000",
            "menuItemOptionId": "00000000-0000-0000-0000-000000000000",
            "authorizedTimestamp": "2022-06-17T17:19:02.281Z",
            "compAmount": 0,
            "compNote": "",
            "modifierDescription": "",
            "orderDetailModifiers": [],
            "totalPrice": 7.49,
            "holdTime": "P12D",
            "stations": [
                {
                    "stationId": "0e39f180-83b7-4c43-b96f-190fa9f0d45a",
                    "stationName": "Bar",
                    "showOnHold": false,
                    "clientId": "63692fe5-2541-9c4e-9501-988fa0414208"
                }
            ],
            "orderTimeStamp": "2022-06-17T17:19:02.281Z",
            "completedTimeStamp": "2022-06-17T17:19:02.281Z",
            "hold": false,
            "submited": false,
            "selected": false,
            "status": "A",
            "abbreviation": "kaleapple smoothie",
            "giftCardNumber": "",
            "cvv": "",
            "cookingStatus": "O"
        }
        ]

    },
    {
        orderDate: "6/9/2022",
        order: [{
            "image": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
            "concept": "SoFresh",
            "properties": [],
            "orderDetailId": "2a4ad7f0-796e-47bd-9bf4-3fc998f40d1e",
            "orderHeaderId": "58e18512-ee62-4276-8276-b69bb290c8e5",
            "seatNumber": 1,
            "sequence": 1,
            "modifiers": [],
            "menuItemId": "2a4544f1-395b-46d0-a872-d0204957317a",
            "menuItem": {
              "menuItemId": "2a4544f1-395b-46d0-a872-d0204957317a",
              "menuItemName": "kale & apple smoothie",
              "tags": "kale apple smoothie",
              "menuItemDescription": "kale, spinach, apple, banana, lemon ",
              "defaultPrice": 7.49,
              "url": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
              "thumbNailUrl": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
              "conceptId": "31f9f250-3a62-4057-90c6-c245a5abe813",
              "modifierDefaults": [],
              "taxable": true,
              "alcohol": false,
              "guestFacing": true,
              "diets": [
                {
                  "dietId": "a3566a37-d14e-4129-9147-90b2a01f4866",
                  "dietName": "V",
                  "guestFacing": true,
                  "url": null
                },
                {
                  "dietId": "8d670de7-559d-4ff9-bee3-c3e873d4635d",
                  "dietName": "GF",
                  "guestFacing": true,
                  "url": null
                }
              ],
              "menuItemOptions": [],
              "calories": 0,
              "fats": 0,
              "carbohydrates": 0,
              "proteins": 0,
              "sodium": 0
            },
            "description": "kale & apple smoothie",
            "notes": "",
            "quantity": 1,
            "paidQuantity": 7.49,
            "price": 7.49,
            "modifierPrice": 0,
            "compGroupId": "00000000-0000-0000-0000-000000000000",
            "compReasonId": "00000000-0000-0000-0000-000000000000",
            "voidReasonId": "00000000-0000-0000-0000-000000000000",
            "authorizedEmployeeId": "00000000-0000-0000-0000-000000000000",
            "menuItemOptionId": "00000000-0000-0000-0000-000000000000",
            "authorizedTimestamp": "2022-06-17T17:19:02.281Z",
            "compAmount": 0,
            "compNote": "",
            "modifierDescription": "",
            "orderDetailModifiers": [],
            "totalPrice": 7.49,
            "holdTime": "P12D",
            "stations": [
                {
                    "stationId": "0e39f180-83b7-4c43-b96f-190fa9f0d45a",
                    "stationName": "Bar",
                    "showOnHold": false,
                    "clientId": "63692fe5-2541-9c4e-9501-988fa0414208"
                }
            ],
            "orderTimeStamp": "2022-06-17T17:19:02.281Z",
            "completedTimeStamp": "2022-06-17T17:19:02.281Z",
            "hold": false,
            "submited": false,
            "selected": false,
            "status": "A",
            "abbreviation": "kaleapple smoothie",
            "giftCardNumber": "",
            "cvv": "",
            "cookingStatus": "O"
        },
        {
            "image": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
            "concept": "SoFresh",
            "properties": [],
            "orderDetailId": "2a4ad7f0-796e-47bd-9bf4-3fc998f40d1e",
            "orderHeaderId": "58e18512-ee62-4276-8276-b69bb290c8e5",
            "seatNumber": 1,
            "sequence": 1,
            "modifiers": [],
            "menuItemId": "2a4544f1-395b-46d0-a872-d0204957317a",
            "menuItem": {
              "menuItemId": "2a4544f1-395b-46d0-a872-d0204957317a",
              "menuItemName": "kale & apple smoothie",
              "tags": "kale apple smoothie",
              "menuItemDescription": "kale, spinach, apple, banana, lemon ",
              "defaultPrice": 7.49,
              "url": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
              "thumbNailUrl": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
              "conceptId": "31f9f250-3a62-4057-90c6-c245a5abe813",
              "modifierDefaults": [],
              "taxable": true,
              "alcohol": false,
              "guestFacing": true,
              "diets": [
                {
                  "dietId": "a3566a37-d14e-4129-9147-90b2a01f4866",
                  "dietName": "V",
                  "guestFacing": true,
                  "url": null
                },
                {
                  "dietId": "8d670de7-559d-4ff9-bee3-c3e873d4635d",
                  "dietName": "GF",
                  "guestFacing": true,
                  "url": null
                }
              ],
              "menuItemOptions": [],
              "calories": 0,
              "fats": 0,
              "carbohydrates": 0,
              "proteins": 0,
              "sodium": 0
            },
            "description": "kale & apple smoothie",
            "notes": "",
            "quantity": 1,
            "paidQuantity": 7.49,
            "price": 7.49,
            "modifierPrice": 0,
            "compGroupId": "00000000-0000-0000-0000-000000000000",
            "compReasonId": "00000000-0000-0000-0000-000000000000",
            "voidReasonId": "00000000-0000-0000-0000-000000000000",
            "authorizedEmployeeId": "00000000-0000-0000-0000-000000000000",
            "menuItemOptionId": "00000000-0000-0000-0000-000000000000",
            "authorizedTimestamp": "2022-06-17T17:19:02.281Z",
            "compAmount": 0,
            "compNote": "",
            "modifierDescription": "",
            "orderDetailModifiers": [],
            "totalPrice": 7.49,
            "holdTime": "P12D",
            "stations": [
                {
                    "stationId": "0e39f180-83b7-4c43-b96f-190fa9f0d45a",
                    "stationName": "Bar",
                    "showOnHold": false,
                    "clientId": "63692fe5-2541-9c4e-9501-988fa0414208"
                }
            ],
            "orderTimeStamp": "2022-06-17T17:19:02.281Z",
            "completedTimeStamp": "2022-06-17T17:19:02.281Z",
            "hold": false,
            "submited": false,
            "selected": false,
            "status": "A",
            "abbreviation": "kaleapple smoothie",
            "giftCardNumber": "",
            "cvv": "",
            "cookingStatus": "O"
        }
        ]

    },
    {
        orderDate: "6/6/2022",
        order: [{
            "image": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
            "concept": "SoFresh",
            "properties": [],
            "orderDetailId": "2a4ad7f0-796e-47bd-9bf4-3fc998f40d1e",
            "orderHeaderId": "58e18512-ee62-4276-8276-b69bb290c8e5",
            "seatNumber": 1,
            "sequence": 1,
            "modifiers": [],
            "menuItemId": "2a4544f1-395b-46d0-a872-d0204957317a",
            "menuItem": {
              "menuItemId": "2a4544f1-395b-46d0-a872-d0204957317a",
              "menuItemName": "kale & apple smoothie",
              "tags": "kale apple smoothie",
              "menuItemDescription": "kale, spinach, apple, banana, lemon ",
              "defaultPrice": 7.49,
              "url": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
              "thumbNailUrl": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
              "conceptId": "31f9f250-3a62-4057-90c6-c245a5abe813",
              "modifierDefaults": [],
              "taxable": true,
              "alcohol": false,
              "guestFacing": true,
              "diets": [
                {
                  "dietId": "a3566a37-d14e-4129-9147-90b2a01f4866",
                  "dietName": "V",
                  "guestFacing": true,
                  "url": null
                },
                {
                  "dietId": "8d670de7-559d-4ff9-bee3-c3e873d4635d",
                  "dietName": "GF",
                  "guestFacing": true,
                  "url": null
                }
              ],
              "menuItemOptions": [],
              "calories": 0,
              "fats": 0,
              "carbohydrates": 0,
              "proteins": 0,
              "sodium": 0
            },
            "description": "kale & apple smoothie",
            "notes": "",
            "quantity": 1,
            "paidQuantity": 7.49,
            "price": 7.49,
            "modifierPrice": 0,
            "compGroupId": "00000000-0000-0000-0000-000000000000",
            "compReasonId": "00000000-0000-0000-0000-000000000000",
            "voidReasonId": "00000000-0000-0000-0000-000000000000",
            "authorizedEmployeeId": "00000000-0000-0000-0000-000000000000",
            "menuItemOptionId": "00000000-0000-0000-0000-000000000000",
            "authorizedTimestamp": "2022-06-17T17:19:02.281Z",
            "compAmount": 0,
            "compNote": "",
            "modifierDescription": "",
            "orderDetailModifiers": [],
            "totalPrice": 7.49,
            "holdTime": "P12D",
            "stations": [
                {
                    "stationId": "0e39f180-83b7-4c43-b96f-190fa9f0d45a",
                    "stationName": "Bar",
                    "showOnHold": false,
                    "clientId": "63692fe5-2541-9c4e-9501-988fa0414208"
                }
            ],
            "orderTimeStamp": "2022-06-17T17:19:02.281Z",
            "completedTimeStamp": "2022-06-17T17:19:02.281Z",
            "hold": false,
            "submited": false,
            "selected": false,
            "status": "A",
            "abbreviation": "kaleapple smoothie",
            "giftCardNumber": "",
            "cvv": "",
            "cookingStatus": "O"
        },
        {
            "image": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
            "concept": "SoFresh",
            "properties": [],
            "orderDetailId": "2a4ad7f0-796e-47bd-9bf4-3fc998f40d1e",
            "orderHeaderId": "58e18512-ee62-4276-8276-b69bb290c8e5",
            "seatNumber": 1,
            "sequence": 1,
            "modifiers": [],
            "menuItemId": "2a4544f1-395b-46d0-a872-d0204957317a",
            "menuItem": {
              "menuItemId": "2a4544f1-395b-46d0-a872-d0204957317a",
              "menuItemName": "kale & apple smoothie",
              "tags": "kale apple smoothie",
              "menuItemDescription": "kale, spinach, apple, banana, lemon ",
              "defaultPrice": 7.49,
              "url": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
              "thumbNailUrl": "https://kluck.s3.amazonaws.com/63692FE5-2541-9C4E-9501-988FA0414208/kaleandapplesmoothie_472761",
              "conceptId": "31f9f250-3a62-4057-90c6-c245a5abe813",
              "modifierDefaults": [],
              "taxable": true,
              "alcohol": false,
              "guestFacing": true,
              "diets": [
                {
                  "dietId": "a3566a37-d14e-4129-9147-90b2a01f4866",
                  "dietName": "V",
                  "guestFacing": true,
                  "url": null
                },
                {
                  "dietId": "8d670de7-559d-4ff9-bee3-c3e873d4635d",
                  "dietName": "GF",
                  "guestFacing": true,
                  "url": null
                }
              ],
              "menuItemOptions": [],
              "calories": 0,
              "fats": 0,
              "carbohydrates": 0,
              "proteins": 0,
              "sodium": 0
            },
            "description": "kale & apple smoothie",
            "notes": "",
            "quantity": 1,
            "paidQuantity": 7.49,
            "price": 7.49,
            "modifierPrice": 0,
            "compGroupId": "00000000-0000-0000-0000-000000000000",
            "compReasonId": "00000000-0000-0000-0000-000000000000",
            "voidReasonId": "00000000-0000-0000-0000-000000000000",
            "authorizedEmployeeId": "00000000-0000-0000-0000-000000000000",
            "menuItemOptionId": "00000000-0000-0000-0000-000000000000",
            "authorizedTimestamp": "2022-06-17T17:19:02.281Z",
            "compAmount": 0,
            "compNote": "",
            "modifierDescription": "",
            "orderDetailModifiers": [],
            "totalPrice": 7.49,
            "holdTime": "P12D",
            "stations": [
                {
                    "stationId": "0e39f180-83b7-4c43-b96f-190fa9f0d45a",
                    "stationName": "Bar",
                    "showOnHold": false,
                    "clientId": "63692fe5-2541-9c4e-9501-988fa0414208"
                }
            ],
            "orderTimeStamp": "2022-06-17T17:19:02.281Z",
            "completedTimeStamp": "2022-06-17T17:19:02.281Z",
            "hold": false,
            "submited": false,
            "selected": false,
            "status": "A",
            "abbreviation": "kaleapple smoothie",
            "giftCardNumber": "",
            "cvv": "",
            "cookingStatus": "O"
        }
        ]

    }
]