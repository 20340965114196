import { Routes, Route, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import axios from 'axios';
import Error from './pages/error/Error';
import Checkout from './pages/checkout/Checkout';
import Loading from './components/loading/Loading';
import LoadingDi from './components/loading/LoadingDi';
import './App.css';
import CheckoutDi from './pages/checkout/CheckoutDi';
import Review from './pages/review/Review';
import Receipt from './pages/receipt/Receipt';

axios.defaults.baseURL =  "https://guest-rest.getkluck.com";


function App() {
  //console.log("BASE URL:" + axios.defaults.baseURL);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/")  window.location.href = "http://getkluck.com"
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  return (
    <Routes>
      <Route path="/*" element={<Error code={404} />}/>
      <Route path="/di/:tablecode" element={<LoadingDi />} />
      <Route path="/di/:tablecode/checkout" element={<CheckoutDi />} />
      <Route path="/oh/:orderHeaderId" element={<LoadingDi oh={true} />} />
      <Route path="/di/:orderHeaderId/checkout" element={<CheckoutDi />} />
      <Route path="/qr/:qrcode" element={<Loading />} />
      <Route path="/r/:receiptId" element={<Receipt />} />
      <Route path="/oo/:qrcode" element={<Loading oo={true} />} />
      <Route path="/oo/:qrcode/checkout" element={<Checkout />} />
      <Route path="/review" element={<Review />} />
      <Route path="/error/:errorcode" element={<Error />} />
      <Route path="/error-404" element={<Error code={404} />} />
      <Route path="/error-500" element={<Error code={500} />} />
    </Routes>
  );
}


export default App;