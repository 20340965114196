import { useState, useEffect } from 'react';
import { ToggleButton, Accordion, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';


const RadioButton = (props) => {
    const [radioValue, setRadioValue] = useState("");
    const [quantity, setQuantity] = useState(1)
    const { modifierGroup, index, editMode, editIndex, lastMod } = props;
    
    const { orderHeader } = useSelector((state) => ({orderHeader: state.orderHeader}));

    useEffect(() => {
      if (editMode) {
        orderHeader.orderDetails[editIndex].modifiers.forEach(element => {
            if (modifierGroup.modifierGroupName === element.modifierGroupName) {
                setRadioValue(element.modifiers[0].modifier.modifierName);
            }
        });
      }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    const OnChangeHandler = (modifier) => {
        setRadioValue(modifier.modifierName);
        setQuantity(1);
    }

    const QuantityChangeHandler = (modifier, x) => {
        var tempQuantity = quantity + x;
        setQuantity(tempQuantity);
        props.ModificationHandler(modifierGroup.modifierGroupName, modifierGroup.modifierGroupId, [{modifier: modifier, quantity: tempQuantity}]);
    }

    const [isExpanded, setIsExpanded] = useState([]);

    const toggle = () => {
        if (isExpanded.length > 0) {
            setIsExpanded([]);
        }
        else (
            setIsExpanded([modifierGroup.modifierGroupName])
        )
    }

    useEffect(() => {
      if (modifierGroup.expanded) setIsExpanded([modifierGroup.modifierGroupName])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    


    return (
        <>
            <Accordion activeKey={isExpanded} >
                <Accordion.Item eventKey={modifierGroup.modifierGroupName}>
                <Accordion.Header onClick={() => toggle()} className={lastMod ? null : "notLastMod"}>
                    <div className="modifierHeader">
                        {(modifierGroup.modifierGroupName)}
                    </div>
                    {modifierGroup.minimum > 0 && 
                        <div className="modifierRequired">
                            {("Required")}
                        </div>}
                    {modifierGroup.maximum > 1 && modifierGroup.uniqueFlag === false &&
                        <div className="modifierChoose">
                            ({("Choose up to ")}{(modifierGroup.maximum)})
                        </div>
                    }
                </Accordion.Header>
                <Accordion.Body className={lastMod ? null : "notLastMod"}> 
                {
                    modifierGroup.modifiers.filter(childs => childs.guestFacing).sort( (a,b) => {return a.sequence - b.sequence}).map(
                        // eslint-disable-next-line array-callback-return
                        (modifier, index1) => {
                            return (
                                <div className="itemModalOptionDiv" key={index1}>
                                    <ToggleButton
                                        className="itemModalOption"
                                        key={index1}
                                        id={`radio-${index}-${index1}`}
                                        type="radio"
                                        variant="secondary"
                                        checked={radioValue === modifier.modifierName}
                                        value={modifier.modifierName}
                                        onChange={() => OnChangeHandler(modifier)}
                                        onClick={() => radioValue !== modifier.modifierName ? props.ModificationHandler(modifierGroup.modifierGroupName, modifierGroup.modifierGroupId, [{modifier: modifier, quantity: quantity}]) : null}
                                    >
                                        <div>
                                            <Form>
                                                <Form.Check 
                                                    type="radio" 
                                                    checked={radioValue === modifier.modifierName} 
                                                    onChange={() => setRadioValue(modifier.modifierName)}
                                                    onClick={() => radioValue !== modifier.modifierName ? props.ModificationHandler(modifierGroup.modifierGroupName, modifierGroup.modifierGroupId, [{modifier: modifier, quantity: quantity}]) : null}
                                                />
                                            </Form>
                                            <div>{modifier.modifierName}</div>
                                        </div>
                                        {(modifier.amountType === "R" && modifier.amount !== 0) &&
                                            <div className="modifierPrice">${modifier.amount.toFixed(2)}</div>
                                        } 
                                        {(modifier.amountType === "M" && modifier.amount !== 0) &&
                                            <div className="modifierPrice">x{modifier.amount}</div>
                                        } 
                                        {(modifier.amountType === "A" && modifier.amount !== 0) &&
                                            <div className="modifierPrice">+${modifier.amount.toFixed(2)}</div>
                                        }
                                    </ToggleButton>
                                    <div>      
                                        {(radioValue === modifier.modifierName && !modifier.uniqueFlag) &&
                                            <div className="modifierQuantityDiv">
                                                {quantity > 1 && 
                                                    <div className="modifierMinus" onClick={() => QuantityChangeHandler(modifier, -1)}>-</div>
                                                }
                                                {quantity < 2 && 
                                                    <div className="modifierMinus" style={{opacity: "0.4"}}>-</div>
                                                }
                                                <p className="modifierQuantity" >{quantity}</p>
                                                {quantity < modifierGroup.maximum && 
                                                    <div className="modifierPlus" onClick={() => QuantityChangeHandler(modifier, 1)}>+</div>
                                                }
                                                {quantity > modifierGroup.maximum - 1 && 
                                                    <div className="modifierPlus" style={{opacity: "0.4"}}>+</div>
                                                }
                                            </div>
                                        }  
                                    </div>
                                </div>
                            )
                        }
                    )
                }
                </Accordion.Body> 
                </Accordion.Item>
            </Accordion>
        </>
    )
}


export default RadioButton;