


const Footer = () => {


    return (
        <div style={{height: "625px"}} />
    )
}


export default Footer;