import { Modal, Dropdown, DropdownButton } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from '../../redux/constants';
import axios from "axios";
import PastOrders from './PastOrders';


const Account = (props) => {
    const { accountMobile, currentPage, modalOnly } = props;
    const [show, setShow] = useState(false);
    const countryCodes = [
        {id: "2DFAF67B-A622-4D4D-BBD2-C29417DF89F2", name: "United States", number: "+1"},
        {id: "345E6488-A334-4C0C-A7CF-F731AD2EAF1C", name: "Afghanistan", number: "+93"},
        {id: "F7E39B1F-70F5-46A7-8ED8-EB4688246523", name: "Albania", number: "+355"},
        {id: "8BC42667-980F-4CAC-AAD8-C7754179C117", name: "Algeria", number: "+213"},
        {id: "DA28514F-0E63-49F2-BCB4-444F8A1A0568", name: "American Samoa", number: "+1684"},
        {id: "F3DD5A24-A44E-4F44-BDBA-D0E450EAE9BF", name: "Andorra", number: "+376"},
        {id: "8B9A8F2D-8E94-4D94-9B51-85E1C1C92A52", name: "Angola", number: "+244"},
        {id: "71D97C2C-FF75-4788-8F23-577B8D188E3B", name: "Anguilla", number: "+1264"},
        {id: "B25B87D7-B8F0-41F8-A9DB-C28BFE8D2837", name: "Antarctica", number: "+0"},
        {id: "B8338674-294E-494B-AFF9-C7FB2076AE5F", name: "Antigua and Barbuda", number: "+1268"},
        {id: "9CD864FE-C213-40E9-855B-F3343EE147B8", name: "Argentina", number: "+54"},
        {id: "8566BB34-18CD-45FF-9DF9-FBCF7AA2577A", name: "Armenia", number: "+374"},
        {id: "D5B36FDE-5BC7-48D9-BEFF-CE8462CA7AEE", name: "Aruba", number: "+297"},
        {id: "7593C768-E65A-4FAF-A650-A90DC5B67CFC", name: "Australia", number: "+61"},
        {id: "047CA8AB-72AD-4390-9098-D99323AD2F3B", name: "Austria", number: "+43"},
        {id: "714430C7-494B-420F-A3C7-56CA9B8B4664", name: "Azerbaijan", number: "+994"},
        {id: "74BBB28E-BCBE-4179-AAAB-87876633DA72", name: "Bahamas", number: "+1242"},
        {id: "C1DD7E5F-79E4-4836-B492-2B118A44A0FD", name: "Bahrain", number: "+973"},
        {id: "23613526-96B1-4C53-ACD6-C48A7E4E2197", name: "Bangladesh", number: "+880"},
        {id: "6DBA25C0-E7DA-46C9-8C15-0C5856D7D7FA", name: "Barbados", number: "+1246"},
        {id: "1FBDADC4-A71C-42AE-99D8-8B3D8546EFF9", name: "Belarus", number: "+375"},
        {id: "9B2EE7BB-5A6D-422D-9C11-0B9831863A7A", name: "Belgium", number: "+32"},
        {id: "84A3AFA3-A0CF-4250-BCCB-1F8A8A2B990D", name: "Belize", number: "+501"},
        {id: "EF2A5D9A-E1EB-441C-9D7B-386819C793CF", name: "Benin", number: "+229"},
        {id: "654CE683-960E-46D1-A10F-1820C17402BD", name: "Bermuda", number: "+1441"},
        {id: "1CF9FF52-B032-4257-9B8C-285F7C83C153", name: "Bhutan", number: "+975"},
        {id: "37D0E90E-606B-4E15-801E-36A71D52D7BA", name: "Bolivia", number: "+591"},
        {id: "6214E906-A576-4457-8464-01CB91B67F24", name: "Bosnia and Herzegovina", number: "+387"},
        {id: "9FD6A499-DCB4-4BEB-8CE4-F66C883F6727", name: "Botswana", number: "+267"},
        {id: "2C2EB866-D380-4724-8994-792D98D3167A", name: "Bouvet Island", number: "+0"},
        {id: "EABF18EC-7807-4136-82DF-CA520B55934D", name: "Brazil", number: "+55"},
        {id: "98796DBC-7BAF-405C-867E-02FDBA198EE2", name: "British Indian Ocean Territory", number: "+246"},
        {id: "76D2025C-8E0C-4F7D-9D5E-50A7D3A65722", name: "Brunei Darussalam", number: "+673"},
        {id: "1CEEE0EC-A8B2-4E48-9086-062CA98CCE23", name: "Bulgaria", number: "+359"},
        {id: "39E9ACE2-8D94-4BDC-AA09-0AB4AF6F8363", name: "Burkina Faso", number: "+226"},
        {id: "CA512BFC-DEC0-462C-87F0-1A0DEBF8B655", name: "Burundi", number: "+257"},
        {id: "B7DE6B1A-4212-4808-B753-E5E557008E20", name: "Cambodia", number: "+855"},
        {id: "A4003C9E-45FE-47A5-85A3-36010C5FD267", name: "Cameroon", number: "+237"},
        {id: "B59E10EB-E84E-4288-B6F9-11E9EDBC8BFC", name: "Canada", number: "+1"},
        {id: "3A43A4CC-04A7-44C3-801D-5B5FCC1160A9", name: "Cape Verde", number: "+238"},
        {id: "50A6F5DA-1374-4746-AFCB-4EBD54BAC0EB", name: "Cayman Islands", number: "+1345"},
        {id: "B2413D3D-6EEA-423E-B9B5-9A35F1CCB64F", name: "Central African Republic", number: "+236"},
        {id: "BD8F31E7-8BBC-414E-9EF2-3BC169BB3AE2", name: "Chad", number: "+235"},
        {id: "C28A5A1A-AD8F-4D16-827D-45D1318AA094", name: "Chile", number: "+56"},
        {id: "B6BEC4E6-FC09-46F6-946A-8DF05D18DE8C", name: "China", number: "+86"},
        {id: "08193DA5-AC41-4895-B2B7-1DD8511E898B", name: "Christmas Island", number: "+61"},
        {id: "6388ED76-5938-4F07-9E15-4CD3A1D6AEFF", name: "Cocos (Keeling) Islands", number: "+672"},
        {id: "E2E3DF3B-C77A-4C37-AF42-5E83339568DA", name: "Colombia", number: "+57"},
        {id: "6AC1188A-1C62-42DC-8AB6-870F48A72B61", name: "Comoros", number: "+269"},
        {id: "33F5EAD1-7C5F-4E0F-9C3B-16B68701C25B", name: "Congo", number: "+242"},
        {id: "F72E784F-5033-4B67-BC18-E4436FFFE4EF", name: "Congo, the Democratic Republic of the", number: "+242"},
        {id: "96F8EA89-A9D6-461B-9742-E5244879D2B1", name: "Cook Islands", number: "+682"},
        {id: "A203CF36-447A-45B3-B573-DBB9BC9E9EC3", name: "Costa Rica", number: "+506"},
        {id: "E8804FCB-0C48-473B-8E4F-99FF1B2EE744", name: "Cote D'Ivoire", number: "+225"},
        {id: "2A61E279-3ADE-44AA-9F4C-4E396D9F00BA", name: "Croatia", number: "+385"},
        {id: "03BD4873-CB9B-44A6-8C83-68F9721751B1", name: "Cuba", number: "+53"},
        {id: "E42D0913-3A5B-4B32-AC9A-F5A9D1C97B67", name: "Cyprus", number: "+357"},
        {id: "9F6DF141-AF7A-4091-97F7-A36902F07232", name: "Czech Republic", number: "+420"},
        {id: "305C4D74-124C-4307-A398-0971A6F6FBAE", name: "Denmark", number: "+45"},
        {id: "D9F2072E-7242-447E-A068-65A1A15A100E", name: "Djibouti", number: "+253"},
        {id: "B13CFB79-18A7-4958-9954-57DB4C38D7E3", name: "Dominica", number: "+1767"},
        {id: "85644A35-EE18-4059-B6F3-39ACF55268EF", name: "Dominican Republic", number: "+1809"},
        {id: "D6A8A082-89DE-4983-9608-9CC1C3A11934", name: "Ecuador", number: "+593"},
        {id: "DFDE12B8-1BD6-4FC6-9450-FEB629679066", name: "Egypt", number: "+20"},
        {id: "C1B21EB7-BD8F-465F-8585-DD2901BA5B9F", name: "El Salvador", number: "+503"},
        {id: "29E547B4-5BA2-4C61-8EA0-FB574EEF4E14", name: "Equatorial Guinea", number: "+240"},
        {id: "438AEC90-042A-42A9-91BE-6538C75A25D9", name: "Eritrea", number: "+291"},
        {id: "0F4CFD0C-4AEE-4BAA-9B6E-03B228D70DC5", name: "Estonia", number: "+372"},
        {id: "0E978D59-6CA2-40BC-A44E-127B56B42CA6", name: "Ethiopia", number: "+251"},
        {id: "E3AF04E6-B707-4390-8808-D7A24871FD90", name: "Falkland Islands (Malvinas)", number: "+500"},
        {id: "B9D4F25B-CD69-473E-8BED-E0CB3FC05161", name: "Faroe Islands", number: "+298"},
        {id: "BB9991BB-3C1D-46D2-9DC4-189A9642268F", name: "Fiji", number: "+679"},
        {id: "ABE85C56-743F-4539-928A-BCDA2F1093D3", name: "Finland", number: "+358"},
        {id: "3EEF6D8A-944A-4E5D-A0C6-162EAA9BC837", name: "France", number: "+33"},
        {id: "246D1C71-EE47-4A85-B02E-564994CA5682", name: "French Guiana", number: "+594"},
        {id: "BC20F8E7-F683-4CBD-990A-1BC190CF147A", name: "French Polynesia", number: "+689"},
        {id: "07FA26A8-D5CF-424B-A61E-460874AA6C4E", name: "French Southern Territories", number: "+0"},
        {id: "A84D405E-8DBF-4804-880B-49647C67D3AC", name: "Gabon", number: "+241"},
        {id: "79152B13-AD7E-4672-B070-C31F1431C7BF", name: "Gambia", number: "+220"},
        {id: "5407DC7D-7886-4E6C-92F6-8E360EA566A9", name: "Georgia", number: "+995"},
        {id: "6786F8DD-82C8-43A9-8C4F-05D3812E6922", name: "Germany", number: "+49"},
        {id: "F4FC321C-4A97-41E5-9FA1-97F361D73BA2", name: "Ghana", number: "+233"},
        {id: "84DE3EED-026B-4681-BD5B-EB05613A440B", name: "Gibraltar", number: "+350"},
        {id: "3F6087F3-F029-4B23-89B8-97E2C98DA5A0", name: "Greece", number: "+30"},
        {id: "1FCF99F0-B9D5-4FCD-A4CD-33A1991072A1", name: "Greenland", number: "+299"},
        {id: "D91D8625-205A-4DFC-8E24-6E751E6D46C9", name: "Grenada", number: "+1473"},
        {id: "2EC18A76-AB5D-4A0D-BC0F-9E4738350DA1", name: "Guadeloupe", number: "+590"},
        {id: "BA3E9453-3815-4146-9F9D-8AFAD26BD71C", name: "Guam", number: "+1671"},
        {id: "48D4C15C-8ED7-4981-B2AD-E3240BA8DA63", name: "Guatemala", number: "+502"},
        {id: "28A7960C-D237-4DA6-AF19-7A33A8F7831F", name: "Guinea", number: "+224"},
        {id: "018BBCC4-FEBA-4129-88A6-DB4498C5B28A", name: "Guinea-Bissau", number: "+245"},
        {id: "B2464683-DDE5-4244-906A-54DD21072379", name: "Guyana", number: "+592"},
        {id: "6FCDDA1C-AD90-46F8-A649-AB3CB19CFC65", name: "Haiti", number: "+509"},
        {id: "41D8D659-0A4A-4470-9F09-486979AC908E", name: "Heard Island and Mcdonald Islands", number: "+0"},
        {id: "A91CA9FB-D28F-4F7B-8690-D77833828CBE", name: "Holy See (Vatican City State)", number: "+39"},
        {id: "EDEE7F43-A14B-40BF-9267-1207DB2E1F40", name: "Honduras", number: "+504"},
        {id: "BE74FE1E-12AE-4CF1-AC30-45592C0192C6", name: "Hong Kong", number: "+852"},
        {id: "6BF8779B-CB65-4724-8AF5-677A509DE158", name: "Hungary", number: "+36"},
        {id: "55DE1BB8-1160-4264-857F-D850668FA1D6", name: "Iceland", number: "+354"},
        {id: "F0970BE3-CBCE-4517-A991-C0BD139EBE11", name: "India", number: "+91"},
        {id: "5EB09603-905B-4939-A4F6-BEFF8DDDF8F5", name: "Indonesia", number: "+62"},
        {id: "340FAE1B-21DD-42D3-9A08-552658EFDA5E", name: "Iran, Islamic Republic of", number: "+98"},
        {id: "BC987D07-ABE5-42FF-89F3-C6BCEBCB675F", name: "Iraq", number: "+964"},
        {id: "A802A232-5137-4CCC-85BC-C8CE9EF2FF80", name: "Ireland", number: "+353"},
        {id: "49CD3DCD-BD1D-4254-963E-F0CC3332E67D", name: "Israel", number: "+972"},
        {id: "42A68111-24F8-4D95-A5C1-57C2B60AF02D", name: "Italy", number: "+39"},
        {id: "C6E65F3E-3C41-4A90-AAF3-C880388B9C43", name: "Jamaica", number: "+1876"},
        {id: "DD604B85-9E07-4CC6-90B4-38683E34D0AB", name: "Japan", number: "+81"},
        {id: "351CBB97-DBC2-4BC3-B012-59F644E5FD43", name: "Jordan", number: "+962"},
        {id: "A3E88ADE-2FE6-44D7-A870-091D13551A49", name: "Kazakhstan", number: "+7"},
        {id: "1EABD5C3-2D00-4061-81F6-8F396631219C", name: "Kenya", number: "+254"},
        {id: "EFCA02AF-35C8-446B-83A2-8947E41275DB", name: "Kiribati", number: "+686"},
        {id: "FE83306C-9703-4559-994D-44ECAE75E264", name: "Korea, Democratic People's Republic of", number: "+850"},
        {id: "4437C33A-0F5E-4F59-BDD2-AB9A2F791272", name: "Korea, Republic of", number: "+82"},
        {id: "ACE8B387-45E8-4CBE-AD6B-363528E16B36", name: "Kuwait", number: "+965"},
        {id: "35082ECC-A54D-438F-8D59-C26EE7E1F8DF", name: "Kyrgyzstan", number: "+996"},
        {id: "E57C3E14-D915-4736-AA18-F93A800FD623", name: "Lao People's Democratic Republic", number: "+856"},
        {id: "A9F85AE3-FB03-4B3F-8FFC-236D9CE47BD5", name: "Latvia", number: "+371"},
        {id: "1F451312-CBC0-4926-8BB7-5ECFCB900B16", name: "Lebanon", number: "+961"},
        {id: "B5B407B9-0BEF-46A7-AD2D-F1AB8CF1D6F5", name: "Lesotho", number: "+266"},
        {id: "F5CB9E24-2575-4139-89CD-A3655B4243B4", name: "Liberia", number: "+231"},
        {id: "3F713B76-448F-4EEA-ADD4-7FDB680B737E", name: "Libyan Arab Jamahiriya", number: "+218"},
        {id: "0F9811C6-D04E-4F75-8ABE-A66528288A06", name: "Liechtenstein", number: "+423"},
        {id: "B69E4867-EAB9-47F1-B7BC-F9832BE60AE7", name: "Lithuania", number: "+370"},
        {id: "7637F9D6-9C17-4EE8-9684-FE1052EE29F8", name: "Luxembourg", number: "+352"},
        {id: "B9B2E699-B745-4286-BD4D-6ECFB825C877", name: "Macao", number: "+853"},
        {id: "5EEE65CA-3A60-40ED-9C36-AEEBDD6BEEE9", name: "Macedonia, the Former Yugoslav Republic of", number: "+389"},
        {id: "E758EE09-23D7-4FFE-A723-5A7D6B1E406E", name: "Madagascar", number: "+261"},
        {id: "24FDE329-7C0C-403F-BEE2-8DDAC6604584", name: "Malawi", number: "+265"},
        {id: "2A80FE2E-BC23-470B-B827-718C300D954B", name: "Malaysia", number: "+60"},
        {id: "AE99C212-D2CB-4640-AD16-057B02C87810", name: "Maldives", number: "+960"},
        {id: "3F1C54B3-4761-4E2E-80EF-20C03AAF3BD5", name: "Mali", number: "+223"},
        {id: "8DF1D327-B566-4C71-8C31-D03F915DF670", name: "Malta", number: "+356"},
        {id: "35646181-2956-4E26-B4DC-3DA21F47780A", name: "Marshall Islands", number: "+692"},
        {id: "BAFAF7ED-5409-482D-85D5-DF46FB275E90", name: "Martinique", number: "+596"},
        {id: "8F597DB1-C4B4-411E-B0F2-DECF0F038DE8", name: "Mauritania", number: "+222"},
        {id: "D6CD9B26-E744-4B87-9CBC-50E885D80213", name: "Mauritius", number: "+230"},
        {id: "1C8CDB6D-7151-4964-90AB-0656D5913458", name: "Mayotte", number: "+269"},
        {id: "20957958-38DB-4438-8E24-F9E4BA126692", name: "Mexico", number: "+52"},
        {id: "D5321A7E-68AA-43CA-9B14-2E4055926641", name: "Micronesia, Federated States of", number: "+691"},
        {id: "C3EAFC99-10FF-4DB1-BA04-4EF0BC0F343D", name: "Moldova, Republic of", number: "+373"},
        {id: "6C8FB2CC-C7BC-48EC-B1A3-9A171AC98D1D", name: "Monaco", number: "+377"},
        {id: "79B7368D-71BC-40DC-9DFC-62E9CD13D1D4", name: "Mongolia", number: "+976"},
        {id: "A2B5C5D0-5770-423E-AFEA-0DE1A8963F57", name: "Montserrat", number: "+1664"},
        {id: "5D484981-AF11-40CD-A0B8-6FD4D708C506", name: "Morocco", number: "+212"},
        {id: "6023BAAC-D3C1-4ACB-B28A-AC7B158B397C", name: "Mozambique", number: "+258"},
        {id: "781A1043-080E-476D-B3BD-12D26679A0D0", name: "Myanmar", number: "+95"},
        {id: "436420D4-5C43-4E3D-9E08-56727A41EEA1", name: "Namibia", number: "+264"},
        {id: "D1FE0C46-BDE3-49FE-9C69-7CCED108258F", name: "Nauru", number: "+674"},
        {id: "9E75030C-E984-4B49-83A0-CC403120639D", name: "Nepal", number: "+977"},
        {id: "901A7A1D-F520-4C6A-A2ED-266C911FDF11", name: "Netherlands", number: "+31"},
        {id: "B9832676-FDDF-4C7A-8E5D-39E7E3F705DD", name: "Netherlands Antilles", number: "+599"},
        {id: "D1508C28-190E-4EA3-94C3-0569B7E50EF9", name: "New Caledonia", number: "+687"},
        {id: "497AD1D5-C176-447F-A62B-CD4B084949C5", name: "New Zealand", number: "+64"},
        {id: "70CBF144-0546-45FE-ACE2-DB78EC895C14", name: "Nicaragua", number: "+505"},
        {id: "2BD296E8-E139-4AF2-80BA-1749AAD23096", name: "Niger", number: "+227"},
        {id: "D06CCC18-0DD9-402E-A6EA-C896B63D84D1", name: "Nigeria", number: "+234"},
        {id: "6F36AD9B-7114-4901-8AE1-22AF05F68FBC", name: "Niue", number: "+683"},
        {id: "04567734-2360-464B-8E58-DAC99084B0F5", name: "Norfolk Island", number: "+672"},
        {id: "0841F507-50A9-48B3-B607-2FEB89BC6696", name: "Northern Mariana Islands", number: "+1670"},
        {id: "4536F3DA-6C29-4761-8AEF-1A9B5CC68B7B", name: "Norway", number: "+47"},
        {id: "DA539609-4EBB-4CB4-9490-242C642C06AF", name: "Oman", number: "+968"},
        {id: "6409444A-ED2F-40B5-AA67-341362BA8458", name: "Pakistan", number: "+92"},
        {id: "0F76041E-85E9-4960-AB2B-FDA4078E50D8", name: "Palau", number: "+680"},
        {id: "2053E84E-4994-42B3-8E2C-7F2ED6CF704C", name: "Palestinian Territory, Occupied", number: "+970"},
        {id: "B538862E-8585-4716-AC7F-E370227EE812", name: "Panama", number: "+507"},
        {id: "3F33C21E-18C3-4365-AB65-05402554B9EA", name: "Papua New Guinea", number: "+675"},
        {id: "CF60ACB5-5DE3-4677-9343-0F16820B3886", name: "Paraguay", number: "+595"},
        {id: "127307EB-2FD8-4459-8DFA-C72498C3EC40", name: "Peru", number: "+51"},
        {id: "E351D5AB-99F9-4580-A7A6-E7D584159C5E", name: "Philippines", number: "+63"},
        {id: "3315E2F6-FF31-4528-9044-60D81A3E8BA5", name: "Pitcairn", number: "+0"},
        {id: "A5C369CD-8AFF-4281-B31F-73DE12A15B5B", name: "Poland", number: "+48"},
        {id: "DE662218-2185-4F0C-A2FA-869CA1ABFDAF", name: "Portugal", number: "+351"},
        {id: "FB3D63C3-6EB5-4DEE-9C2E-AC2C5070A7D3", name: "Puerto Rico", number: "+1787"},
        {id: "44E050AC-D7BF-433C-AF03-46EEEA58158E", name: "Qatar", number: "+974"},
        {id: "2901EE8E-5E3B-4DA0-AE1E-B3BFFA133D09", name: "Reunion", number: "+262"},
        {id: "06B886A6-6294-4886-B801-DC319EA050D4", name: "Romania", number: "+40"},
        {id: "2CACD9FE-C4C8-48B6-A525-3AFA25326376", name: "Russian Federation", number: "+70"},
        {id: "A9597EB6-FF3B-45DC-9EE9-3AE86AE8E2D2", name: "Rwanda", number: "+250"},
        {id: "0DA7D96B-BAA8-44A6-BDE4-D9DFCDC72A94", name: "Saint Helena", number: "+290"},
        {id: "851E274E-B33D-433E-A37B-944AB7368F74", name: "Saint Kitts and Nevis", number: "+1869"},
        {id: "96A8D76B-363A-4DA3-99A7-2663C65D9144", name: "Saint Lucia", number: "+1758"},
        {id: "24F1E52E-CC41-4CE2-B5AC-405F3B1032BE", name: "Saint Pierre and Miquelon", number: "+508"},
        {id: "25A67318-62DA-4644-A196-00DB6A5777F6", name: "Saint Vincent and the Grenadines", number: "+1784"},
        {id: "5C7AEFC1-500F-453C-8963-81710F07592F", name: "Samoa", number: "+684"},
        {id: "8EE32435-1DC5-4CFF-AF62-082C58B19207", name: "San Marino", number: "+378"},
        {id: "A0FE3B2F-9A38-4E94-823A-50E23286E310", name: "Sao Tome and Principe", number: "+239"},
        {id: "7E693138-1387-4B08-A26F-0F5E5E11497E", name: "Saudi Arabia", number: "+966"},
        {id: "D56C6058-CF63-4A86-AA34-2727DA89E2E4", name: "Senegal", number: "+221"},
        {id: "6D57F593-9B82-4D42-B773-57337984B438", name: "Serbia and Montenegro", number: "+381"},
        {id: "2F8997B9-101F-4F1E-B428-81285870567F", name: "Seychelles", number: "+248"},
        {id: "A172B9FE-A2F9-46FE-B20F-07173D622810", name: "Sierra Leone", number: "+232"},
        {id: "CD316B6B-81E3-4305-9FEF-6956A63B1AB3", name: "Singapore", number: "+65"},
        {id: "5D4BBDAE-F1AC-425C-A4DD-B07613E1735D", name: "Slovakia", number: "+421"},
        {id: "DDBA100E-3E7B-4AA0-9DCC-F5F6A77D725A", name: "Slovenia", number: "+386"},
        {id: "EA114210-618A-4E7A-B53D-BC03135CBBD6", name: "Solomon Islands", number: "+677"},
        {id: "508DF1B7-EE52-4AE3-BF36-25E943AF3F08", name: "Somalia", number: "+252"},
        {id: "FF745B86-5F4A-420F-81C2-C1F822DB3B26", name: "South Africa", number: "+27"},
        {id: "E9A14062-ADF0-4198-BA63-599402278CB1", name: "South Georgia and the South Sandwich Islands", number: "+0"},
        {id: "699B9422-225F-4281-BA3F-8ACB6D8B162E", name: "Spain", number: "+34"},
        {id: "1EE7026C-0E50-4FB3-9C1E-02261BF6D692", name: "Sri Lanka", number: "+94"},
        {id: "B0ACEBDD-E0B2-4A42-93EF-40E3F5296893", name: "Sudan", number: "+249"},
        {id: "8C399FC5-D7B3-4026-8DB8-54EF68871C25", name: "Suriname", number: "+597"},
        {id: "97835225-E485-469C-9290-07A77C63DD7E", name: "Svalbard and Jan Mayen", number: "+47"},
        {id: "7BB407BC-7CB6-4FE5-88AA-58E778114C14", name: "Swaziland", number: "+268"},
        {id: "4F930125-DB0E-45D7-BF01-EE4319E3BBE0", name: "Sweden", number: "+46"},
        {id: "27B58F1F-DB01-4B85-8FA0-1F10B14005DA", name: "Switzerland", number: "+41"},
        {id: "815AE565-9145-4C4C-B236-AFFE7B65B89E", name: "Syrian Arab Republic", number: "+963"},
        {id: "AF8859BD-E5A8-44BD-9CB2-32F9809658ED", name: "Taiwan, Province of China", number: "+886"},
        {id: "53D1D575-FD17-4F0E-BB4C-6EB7EEF137B1", name: "Tajikistan", number: "+992"},
        {id: "942E6FEA-43B5-4E5B-9650-B698D6DA52BB", name: "Tanzania, United Republic of", number: "+255"},
        {id: "8ED6D6D6-3525-476B-A317-74917E2BFA8B", name: "Thailand", number: "+66"},
        {id: "7424255A-7BEA-4BBC-B8AC-1459F6CEE59B", name: "Timor-Leste", number: "+670"},
        {id: "81A464AF-200E-40ED-A07C-F89B637CDCE7", name: "Togo", number: "+228"},
        {id: "D83013A0-DF1A-4C98-9ED9-427A048009BC", name: "Tokelau", number: "+690"},
        {id: "2B6C8CD1-1471-4444-8455-841A4C03D575", name: "Tonga", number: "+676"},
        {id: "DD12142A-8FED-4313-A98E-AE235903D1E9", name: "Trinidad and Tobago", number: "+1868"},
        {id: "E8D10A45-9F2F-4EBC-A016-7C6ADEA65599", name: "Tunisia", number: "+216"},
        {id: "5B58B671-9D4D-42E2-B251-E703E2773237", name: "Turkey", number: "+90"},
        {id: "F6564F0C-8781-4251-AE21-B0470B536091", name: "Turkmenistan", number: "+7370"},
        {id: "EE552980-7910-460F-B31F-D080948797E4", name: "Turks and Caicos Islands", number: "+1649"},
        {id: "C4006F7B-0D66-4020-9D37-C20869A3EFE2", name: "Tuvalu", number: "+688"},
        {id: "0D8EF387-9890-47FF-81F0-B62923EB6BAB", name: "Uganda", number: "+256"},
        {id: "809AAD74-F6A2-48C3-89C3-631DB4046DA9", name: "Ukraine", number: "+380"},
        {id: "35892EAD-D06D-4487-A792-03B5CD40388E", name: "United Arab Emirates", number: "+971"},
        {id: "EA1AE6DF-7D85-4392-B901-4307E7FDDFEB", name: "United Kingdom", number: "+44"},
        {id: "F8AE48F4-09EB-48EB-95F9-2CEF77A33B54", name: "United States Minor Outlying Islands", number: "+1"},
        {id: "92150DAB-D515-4415-9AAE-F6CD1157DDF5", name: "Uruguay", number: "+598"},
        {id: "10132C8D-373F-418D-8709-CF4F7E7B5B97", name: "Uzbekistan", number: "+998"},
        {id: "49866CC8-1E71-4980-81E7-FD38597C1E63", name: "Vanuatu", number: "+678"},
        {id: "70E4442E-1167-4162-B27E-5E71D8BE9282", name: "Venezuela", number: "+58"},
        {id: "A70078D9-3D03-424A-99DE-66E378EF7D5D", name: "Viet Nam", number: "+84"},
        {id: "18B7EFA9-E044-4BB5-9EDE-CF32B07C75B2", name: "Virgin Islands, British", number: "+1284"},
        {id: "126BB0AD-B176-4726-A512-649E968A4A69", name: "Virgin Islands, U.s.", number: "+1340"},
        {id: "8B53D770-0731-436F-B464-1BD89C471588", name: "Wallis and Futuna", number: "+681"},
        {id: "F0B07403-D065-4B25-A263-D79EF2D41D27", name: "Western Sahara", number: "+212"},
        {id: "5F487620-5D4C-4654-BA2A-DB3AFFD26AC7", name: "Yemen", number: "+967"},
        {id: "CAD8768A-C9CF-4FA0-A64E-EE240524074C", name: "Zambia", number: "+260"},
        {id: "C3480630-4A30-4508-B1A3-45A505A4C238", name: "Zimbabwe", number: "+263"} 
    ];

    const sendCodeHandler = () => {
        let phoneNumber = document.getElementById("phoneInput").value;
        let countryNumber = country.number;
        let countryId = country.id;
        axios.post("https://guest-rest-development.getkluck.com/users/login/phone/" + countryNumber + phoneNumber)
            .then((response) => {
              setPhoneData({
                countryId: countryId,
                phoneNumber: phoneNumber,
                passCode: ""
              })
            })
        
        setEnterNumber(false);
    }

    const [enterNumber, setEnterNumber] = useState(true);

    const checkCodeInputHandler = (id) => {
        let value = parseInt(document.getElementById(id).value);
        if (!(value <= 9 && value >= 0)) document.getElementById(id).value = "";
    }

    const [phoneData, setPhoneData] = useState({countryId: "", phoneNumber: "", passCode: ""})

    const inputChangeHandler = (currentNumber) => {
        let input1 = document.getElementById("loginInput1").value;
        let input2 = document.getElementById("loginInput2").value;
        let input3 = document.getElementById("loginInput3").value;
        let input4 = document.getElementById("loginInput4").value;
        let input5 = document.getElementById("loginInput5").value;
        let input6 = document.getElementById("loginInput6").value;      

        if (document.getElementById("loginInput" + currentNumber).value !== "") {
            if ((input1 + input2 + input3 + input4 + input5 + input6).length === 6) document.getElementById("loginInput" + currentNumber).blur();
            else if (currentNumber !== 6) document.getElementById("loginInput" + (currentNumber + 1)).focus();
        }       
        let code = input1 + input2 + input3 + input4 + input5 + input6
        if (code.length === 6) codeValidator(code);
    }

    function debounce(callback, interval) {
        let debounceTimeoutId;
        
        return function(...args) {
          clearTimeout(debounceTimeoutId);
          debounceTimeoutId = setTimeout(() => callback.apply(this, args), interval);
        };
    }
    
    const { accountData } = useSelector((state) => ({accountData: state.accountData}));
    const { loggedIn } = useSelector((state) => ({loggedIn: state.loggedIn}));
    const dispatch = useDispatch();

    const codeValidator = debounce((code) => {
        let data = phoneData;
        data.passCode = code;
        axios.post("https://guest-rest-development.getkluck.com/authenticate/phone", data)
            .then((response) => {
                document.cookie = "token=" + response.data.token;
                axios.defaults.headers.common['Authorization'] = 'Bearer '+ response.data.token;
                axios.get("https://guest-rest-development.getkluck.com/users")
                    .then((response) => {
                        document.getElementById("loginInput1").style.border = "1px solid #49be25";
                        document.getElementById("loginInput2").style.border = "1px solid #49be25";
                        document.getElementById("loginInput3").style.border = "1px solid #49be25";
                        document.getElementById("loginInput4").style.border = "1px solid #49be25";
                        document.getElementById("loginInput5").style.border = "1px solid #49be25";
                        document.getElementById("loginInput6").style.border = "1px solid #49be25";
                        addAccountData(response.data);
                        document.cookie = "accountData=" + JSON.stringify(response.data);
                    })
            })
            .catch((error) => {
                document.getElementById("loginInput1").style.border = "1px solid red";
                document.getElementById("loginInput2").style.border = "1px solid red";
                document.getElementById("loginInput3").style.border = "1px solid red";
                document.getElementById("loginInput4").style.border = "1px solid red";
                document.getElementById("loginInput5").style.border = "1px solid red";
                document.getElementById("loginInput6").style.border = "1px solid red";
                console.log("ERROR:" + error);
            })         
    }, 500)

    const addAccountData = debounce((data) => {
        dispatch({type:ActionTypes.ADD_ACCOUNT_DATA, payload: {accountData: data}});
        setShow(false);
        if (modalOnly) props.login();
        else props.changePage("Home");
    }, 1000)


    const [editAccount, setEditAccount] = useState(false);

    useEffect(() => {
      if (editAccount) {
        document.getElementById("firstNameInput").value = accountData.firstName;
        document.getElementById("lastNameInput").value = accountData.lastName;
        document.getElementById("emailInput").value = accountData.email;
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editAccount])
    

    const saveAccountData = () => {
        let tempAccountData = {};

        tempAccountData.firstName = document.getElementById("firstNameInput").value;
        tempAccountData.lastName = document.getElementById("lastNameInput").value;
        tempAccountData.email = document.getElementById("emailInput").value;

        if (tempAccountData.firstName !== accountData.firstName || tempAccountData.lastName !== accountData.lastName || tempAccountData.email !== accountData.email) {
            let x = accountData;
            accountData.firstName = tempAccountData.firstName;
            accountData.lastName = tempAccountData.lastName;
            accountData.email = tempAccountData.email;
            updateAccountData(x);
        }

        setEditAccount(!editAccount);
    }

    const updateAccountData = (tempAccountData) => {
        axios.put("https://guest-rest-development.getkluck.com/users", tempAccountData)
            .then((response) => {
                axios.get("https://guest-rest-development.getkluck.com/users")
                    .then((response) => {
                        dispatch({type:ActionTypes.ADD_ACCOUNT_DATA, payload: {accountData: response.data}});
                    })
            })
            .catch((error) => console.log("ERROR:" + error));
    }

    useEffect(() => {
        setEditAccount(false);
    }, [currentPage])
    
    useEffect(() => {
        setEditAccount(false);
    }, [show])

    const [country, setCountry] = useState({id: "2DFAF67B-A622-4D4D-BBD2-C29417DF89F2", name: "United States", number: "+1"});

    useEffect(() => {
      if (modalOnly) setShow(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const [showPastOrders, setShowPastOrders] = useState(false);

    return (
        <>
            {(accountMobile && !modalOnly) &&
                <div className="bottomBarButton" onClick={() => props.changePage("Account")}>
                    <svg className="iconSvg" viewBox="0 0 24 24">
                        <path className={currentPage === "Account" ? "currentPage" : null} d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
                    </svg>
                    <p className={currentPage === "Account" ? "currentPage" : null}>{loggedIn ? "Account" : "Login"}</p>
                </div>
            }
            {(!accountMobile && !modalOnly) &&
                <div onClick={() => setShow(!show)} className="loginButton cartNotMobile">
                    <svg viewBox="0 0 18 18">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 4.5C12 5.29565 11.6839 6.05871 11.1213 6.62132C10.5587 7.18393 9.79565 7.5 9 7.5C8.20435 7.5 7.44129 7.18393 6.87868 6.62132C6.31607 6.05871 6 5.29565 6 4.5C6 3.70435 6.31607 2.94129 6.87868 2.37868C7.44129 1.81607 8.20435 1.5 9 1.5C9.79565 1.5 10.5587 1.81607 11.1213 2.37868C11.6839 2.94129 12 3.70435 12 4.5ZM9 9C10.1935 9 11.3381 8.52589 12.182 7.68198C13.0259 6.83807 13.5 5.69347 13.5 4.5C13.5 3.30653 13.0259 2.16193 12.182 1.31802C11.3381 0.474106 10.1935 0 9 0C7.80653 0 6.66193 0.474106 5.81802 1.31802C4.97411 2.16193 4.5 3.30653 4.5 4.5C4.5 5.69347 4.97411 6.83807 5.81802 7.68198C6.66193 8.52589 7.80653 9 9 9ZM18 16.5C18 18 16.5 18 16.5 18H1.5C1.5 18 0 18 0 16.5C0 15 1.5 10.5 9 10.5C16.5 10.5 18 15 18 16.5ZM16.5 16.494C16.4985 16.125 16.269 15.015 15.252 13.998C14.274 13.02 12.4335 12 9 12C5.565 12 3.726 13.02 2.748 13.998C1.731 15.015 1.503 16.125 1.5 16.494H16.5Z"/>
                    </svg>
                    <div>{loggedIn ? "Account" : "Login"}</div>
                </div>
            }
            <Modal show={accountMobile ? currentPage === "Account" : show}>
                <>
                    {(!accountMobile && !modalOnly) &&
                        <div className="modalX" onClick={() => setShow(false)}>
                            <svg viewBox="0 0 24 24">
                                <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
                            </svg>
                        </div>  
                    }
                    {!loggedIn &&
                        <>
                            <p className="modalTitle">Login</p>
                            {enterNumber &&
                                <div className="phoneDiv">
                                    <DropdownButton id="countryId" title={country.number}>
                                        {
                                            countryCodes.map((country, index) => {
                                                return <Dropdown.Item key={index} onClick={() => setCountry(country)}>{country.number} {country.name}</Dropdown.Item>
                                            })
                                        }
                                    </DropdownButton>
                                    <input type="number" id="phoneInput" autoComplete="off" placeholder="Phone Number" pattern="\d*" />
                                </div>
                            }
                            {!enterNumber &&
                                <div className="loginInputDiv">
                                    <input id="loginInput1" type="text" maxLength="1" pattern="\d*" autoComplete="off" onInput={() => checkCodeInputHandler("loginInput1")} onChange={() => inputChangeHandler(1)} />
                                    <input id="loginInput2" type="text" maxLength="1" pattern="\d*" autoComplete="off" onInput={() => checkCodeInputHandler("loginInput2")} onChange={() => inputChangeHandler(2)} />
                                    <input id="loginInput3" type="text" maxLength="1" pattern="\d*" autoComplete="off" onInput={() => checkCodeInputHandler("loginInput3")} onChange={() => inputChangeHandler(3)} />
                                    <input id="loginInput4" type="text" maxLength="1" pattern="\d*" autoComplete="off" onInput={() => checkCodeInputHandler("loginInput4")} onChange={() => inputChangeHandler(4)} />
                                    <input id="loginInput5" type="text" maxLength="1" pattern="\d*" autoComplete="off" onInput={() => checkCodeInputHandler("loginInput5")} onChange={() => inputChangeHandler(5)} />
                                    <input id="loginInput6" type="text" maxLength="1" pattern="\d*" autoComplete="off" onInput={() => checkCodeInputHandler("loginInput6")} onChange={() => inputChangeHandler(6)} />
                                </div>
                            }
                        </>
                    }
                    {loggedIn &&
                        <>
                            <p className="modalTitle">
                                {accountData.firstName}
                                {!editAccount &&
                                    <svg viewBox="0 0 24 24" onClick={() => setEditAccount(true)} style={{height: "1em", margin: "0 0 0.15em 0.2em"}}>
                                        <path fill="currentColor" d="M21.7,13.35L20.7,14.35L18.65,12.3L19.65,11.3C19.86,11.09 20.21,11.09 20.42,11.3L21.7,12.58C21.91,12.79 21.91,13.14 21.7,13.35M12,18.94L18.06,12.88L20.11,14.93L14.06,21H12V18.94M12,14C7.58,14 4,15.79 4,18V20H10V18.11L14,14.11C13.34,14.03 12.67,14 12,14M12,4A4,4 0 0,0 8,8A4,4 0 0,0 12,12A4,4 0 0,0 16,8A4,4 0 0,0 12,4Z" />
                                    </svg>
                                }
                            </p>
                            {!editAccount &&
                                <>
                                    <div className="dataDiv">
                                        <div>Email:</div>
                                        <div>{accountData.email}</div>
                                    </div>
                                    <div style={{padding: "12px"}}><button style={{border: "none"}} className="itemModalAddToCart" onClick={() => setShowPastOrders(!showPastOrders)}>Past Orders</button></div>
                                    <PastOrders show={showPastOrders} setShow={() => setShowPastOrders} setAccountShow={() => setShow} />
                                </>
                            }
                            {editAccount &&
                                <>  
                                    <div className="dataDiv">
                                        <div>First Name:</div>
                                        <input id="firstNameInput" type="text" />
                                    </div>
                                    <div className="dataDiv">
                                        <div>Last Name:</div>
                                        <input id="lastNameInput" type="text" />
                                    </div>
                                    <div className="dataDiv">
                                        <div>Email:</div>
                                        <input id="emailInput" type="text" />  
                                    </div>
                                </>
                            }
                        </>
                    }
                </>
                {((!loggedIn && enterNumber) || (loggedIn && editAccount)) &&
                    <div className="itemModalFooter">
                        {!loggedIn &&
                            <div className="itemModalAddToCart" onClick={() => sendCodeHandler()}><p>Send Verification Code</p></div>
                        }
                        {loggedIn &&
                            <div className="itemModalAddToCart" onClick={() => saveAccountData()} ><p>Save</p></div>
                        }
                    </div>
                }
            </Modal>
        </>
    )
}


export default Account;