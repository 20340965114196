export const ActionTypes = {
    ADD_CONCEPT: '@@ADD_CONCEPT',
    CLIENT_ID: '@@CLIENT_ID',
    CREDENTIAL: '@@CREDENTIAL',
    INIT: '@@INIT',
    IP_ADDRESS: '@@IP_ADDRESS',
    LANGUAGE: '@@LANGUAGE',
    LOCATION_ID: '@@LOCATION_ID',
    LOCATION_INFO: '@@LOCATION_INFO',
    LOCATION_NAME: '@@LOCATION_NAME',
    MAC_ADDRESS: '@@MAC_ADDRESS',
    MENU: '@@MENU',
    NATIVE_LANGUAGES: '@@NATIVE_LANGUAGES',
    TOKEN: '@@TOKEN',
    USER_CACHE: '@@USER_CACHE',
    TERMINAL_ID: '@@TERMINAL_ID',
    ADD_MENU: '@@ADD_MENU',
    ADD_PROMOTION: '@@ADD_PROMOTION',
    ADD_MODIFICATION_GROUP: '@@ADD_MODIFICATION_GROUP',
    ADD_86: '@@ADD_86',
    ADD_CLIENT_ID: '@@ADD_CLIENT_ID',
    ADD_ACCOUNT_DATA: '@@ADD_ACCOUNT_DATA',
    CREATE_CART: '@@CREATE_CART',
    UPDATE_CART: '@@UPDATE_CART',
    UPDATE_CART_AMOUNT: '@@UPDATE_CART_AMOUNT',
    CART_UPDATE: '@@CART_UPDATE',
    ADD_RECEIPT_DATA: '@@ADD_RECEIPT_DATA',
};