import { Modal } from "react-bootstrap";
import { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from "react-redux";

const Share = (props) => {
    const { firstPerson } = props;
    const [show, setShow] = useState(false);
    const [phone, setPhone] = useState("");
    const { orderHeader } = useSelector((state) => ({orderHeader: state.orderHeader}));

    useEffect(() => {
      if (!firstPerson) setShow(true);
    }, [firstPerson])

    const sendPhone = () => {
        console.log(phone);
        //const orderHeaderId = "58e18512-ee62-4276-8276-b69bb290c8e5";
        //const message = 'Share ' + orderHeaderId;
        //let qrText = `(smsto:+18634519554:${message})`;
        //console.log(qrText);
    }


    return (
        <>
            {firstPerson &&
                <div className="shareDiv">
                    <div className="itemModalAddToCart" onClick={() => setShow(!show)}><p>Share</p></div>
                </div>
            }
            <Modal show={show} onHide={() => setShow(false)} centered dialogClassName={firstPerson ? "qrModal" : null} backdrop={firstPerson ? true : "static"}>
                <Modal.Body style={{padingRight: "none"}}>
                    {firstPerson &&
                        <div style={{display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
                            <QRCode value={"http://" + window.location.host + "/oh/" + orderHeader.orderHeaderId} style={{pointerEvents: "none"}} />
                            <div className="qrModalRightDiv" style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "256px", width: "256px", marginLeft: "1rem"}}>
                                <div>Have your friends scan this qr code<div style={{padding: "1rem 0"}}>or</div></div>
                                <div>
                                    <div style={{padding: "1rem 0"}}>Type in your friend's number and click the arrow to send them an invite.</div>
                                    <div style={{width: "256px", display: "flex"}}>
                                        <input style={{borderRadius: "5px 0 0 5px", width: "100%"}} value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="(123) 456-7890" type="number" />
                                        <div style={{border: "2px solid #ADB5BD", borderLeft: "none", borderRadius: "0 5px 5px 0", cursor: "pointer", padding: "6px"}} onClick={() => sendPhone()}>
                                            <svg style={{width: "24px", height: "24px", marginBottom: "1px"}} viewBox="0 0 24 24">
                                                <path fill="#ADB5BD" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {!firstPerson &&
                        <div style={{textAlign: "center"}}>
                            <div className="itemModalAddToCart" style={{marginBottom: "12px"}} onClick={() => setShow(true)}><p>Create New Order</p></div>
                            <div className="itemModalAddToCart" onClick={() => setShow(true)}><p>Join an Order</p></div>
                        </div>
                    }
                </Modal.Body>
            </Modal>

        </>
    )
}
//Use the camera app on your phone to scan the table leader's qr code.
export default Share;