import React, {useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Concept from "./Concept";


const ConceptSelector = (props) => {
    const { selectedConcepts } = props;
    const [conceptArray, setConceptArray] = useState([]);
    const { concepts } = useSelector((state) => ({concepts: state.concepts}));
    const [singleConcept, setSingleConcept] = useState(false);

    useEffect( () => {
        const tempArray = [];
        // eslint-disable-next-line no-unused-vars
        for (const [name, value] of concepts) {
            tempArray.push(value);
        }
        setConceptArray(tempArray);
    },[concepts]); 

    useEffect(() => {
        if (concepts.size === 1) setSingleConcept(true);
        else setSingleConcept(false);
      }, [concepts])
    
    const onConceptChangeHandler = (concept) => {
        if (props.onConceptChange) {
            props.onConceptChange(concept);
        }
    }
    

    return (
        <div className="concepts">
            {!singleConcept &&
                <div>Select your resaurants</div>
            }
            <div>
                {
                    conceptArray.map((child,index) => {
                        return (
                            <Concept 
                                concept={child}
                                key={index} 
                                onConceptChange={() => onConceptChangeHandler(child)} 
                                singleConcept={singleConcept} 
                                selectedConcepts={selectedConcepts}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}


export default ConceptSelector;