import { v4 as iiudv4 } from 'uuid';


class OrderHeader {
    
    constructor() {
        var date =  new Date().toISOString();
        this.properties = [];
        this.orderHeaderId =  iiudv4();
        this.clientId = null;
        this.menuId = null;
        this.locationId = null;
        this.startTime = date;
        this.endTimeStamp = null;
        this.tableName = null;
        this.terminalName = null;
        this.orderTypeId = null;
        this.orderModeId = null;
        this.orderSourceId = null;
        this.numberOfGuests = 1;
        this.orderDetails = [];
        this.voidReasonId = "00000000-0000-0000-0000-000000000000";
        this.authorizedEmployeeId = "00000000-0000-0000-0000-000000000000";
        this.seatingAreaId = "00000000-0000-0000-0000-000000000000";
        this.authorizedTimestamp = null;
        this.compGroupId = "00000000-0000-0000-0000-000000000000";
        this.compReasonId = "00000000-0000-0000-0000-000000000000";
        this.compAmount = 0;
        this.compNote = "";
        this.subTotal = 0;
        this.compTotal = 0;
        this.salesTax = 0;
        this.tip = 0;
        this.tipPercent = 0;
        this.total = this.subTotal+this.salesTax+this.tip;
        this.guestName = null;
        this.notes = "";
        this.message = "";
        this.Name = "kiosk";
        this.phoneNumber = null;
        this.status = "O";
        this.promiseTime = null;
        this.cookingStatus = "O";
        this.guestInfoDisplayed = null;
        this.taxable = true;
        this.address1 = null;
        this.address2 = null;
        this.city = null;
        this.state = null;
        this.postalCode = null;
        this.subTotalNonAlcohol = null;
        this.orderEmployees = [{
            orderEmployeeId: "31d6def8-3c57-46ab-aa75-7780f5030e27",
            clientId: this.clientId,
            locationId: this.locationId,
            employeeId: "00000000-0000-0000-0000-000000000000",
            activeEmployee: true,
            orderHeaderId: this.orderHeaderId,
            percentage: 100}];
    }

    addOrderDetails(menuItem, modifiers, quantity, paidQuantity, price, modifierPrice, totalPrice, concept, stations) {
        var date =  new Date().toISOString();
        //console.log(menuItem, modifiers, quantity, paidQuantity, price, modifierPrice, totalPrice, concept, stations)
        const orderDetail = {
            menuItem: menuItem,
            sectionId: menuItem.sectionId,
            image : menuItem.thumbNailUrl,
            concept: concept,
            properties: [],
            orderDetailId: iiudv4(),
            orderHeaderId: this.orderHeaderId,
            seatNumber: 1,
            sequence: 1,
            modifiers: modifiers,
            menuItemId: menuItem.menuItemId,
            description: menuItem.menuItemName,
            notes: "",
            quantity: quantity,
            paidQuantity: paidQuantity,
            price: price,
            modifierPrice: modifierPrice,
            compGroupId: "00000000-0000-0000-0000-000000000000",
            compReasonId: "00000000-0000-0000-0000-000000000000",
            voidReasonId: "00000000-0000-0000-0000-000000000000",
            authorizedEmployeeId: "00000000-0000-0000-0000-000000000000",
            menuItemOptionId: "00000000-0000-0000-0000-000000000000",
            authorizedTimestamp: date,
            compAmount: 0,
            compNote: "",
            modifierDescription: "",
            orderDetailModifiers: [],
            totalPrice: (price + modifierPrice) * quantity,
            holdTime: "P12D",
            stations: stations,
            //
            orderTimeStamp: date,
            completedTimeStamp: date,
            //
            hold: false,
            submited: false,
            selected: false,
            status: "A",
            abbreviation: menuItem.menuItemName,
            giftCardNumber: "",
            cvv: "",
            cookingStatus: "O"
        }
        this.orderDetails.push(orderDetail)
        this.subTotal = this.subTotal + orderDetail.price + orderDetail.modifierPrice;
        this.total = this.total + ((price + modifierPrice) * quantity);
    }

    deleteOrderDetails(child, index) {
        //console.log(child,index)
        this.orderDetails.splice(index, 1)
        this.total = this.total - child.totalPrice;
        this.subTotal = this.subTotal - child.totalPrice;
    }

    addQuantOrderDetails(child, index) {
        //console.log(child)
        this.orderDetails[index].quantity = this.orderDetails[index].quantity + 1;
        this.orderDetails[index].paidQuantity = this.orderDetails[index].paidQuantity + (child.price + this.orderDetails[index].modifierPrice);
        this.orderDetails[index].totalPrice = this.orderDetails[index].totalPrice + (child.price + this.orderDetails[index].modifierPrice);
        this.subTotal = this.subTotal + (child.price + this.orderDetails[index].modifierPrice) ;
        this.total = this.total + (child.price + this.orderDetails[index].modifierPrice) ;
        //console.log(this.orderDetails)
    }
    
    subQuantOrderDetails(child, index) {
        this.orderDetails[index].quantity = this.orderDetails[index].quantity - 1;
        this.orderDetails[index].paidQuantity = this.orderDetails[index].paidQuantity - (child.price + this.orderDetails[index].modifierPrice);
        this.orderDetails[index].totalPrice = this.orderDetails[index].totalPrice - (child.price + this.orderDetails[index].modifierPrice);
        this.subTotal =   this.subTotal -  (child.price + this.orderDetails[index].modifierPrice);
        this.total = this.total - (child.price + this.orderDetails[index].modifierPrice);
    }

    clearOrderDetails() {
        this.orderDetails = [];
        this.subTotal = 0;
        this.total = 0;
    }

    isPromoted(promotions,menuItem,level) {
        const tempArray = [];
        const testMenuItem = (menuItem, promotion) => {
            if (menuItem !== null && menuItem.menuItemId === promotion.menuItemId) return false;
            if (promotion.menuItemFilter === "A") return true;
            if (promotion.menuItemFilter === "I") {
               for (let j = 0; j < promotion.promotionMenuItems.length; j++) {
                    if (promotion.promotionMenuItems[j].menuItemId === menuItem.menuItemId) return true;
                }
            }
            if (promotion.menuItemFilter === "E") {
                for (let j = 0; j < promotion.promotionMenuItems.length; j++) {
                    if (promotion.promotionMenuItems[j].menuItemId !== menuItem.menuItemId) return true;
                }
            }
            return false
        }
        const testMenuItemCategory = (menuItem,promotion) => {
            if (promotion.menuItemCategoryFilter === "A") return true;
            if (promotion.menuItemCategoryFilter === "I") {
               for (let j = 0; j < promotion.promotionMenuItemCategories.length; j++) {
                    if (promotion.promotionMenuItemCategories[j].menuItemCategoryId === menuItem.categoryId) return true;
                }
            }
            if (promotion.menuItemCategoryFilter === "E") {
                for (let j = 0; j < promotion.promotionMenuItemCategories.length; j++) {
                    if (promotion.promotionMenuItemCategories[j].menuItemCategoryId !== menuItem.categoryId) return true;
                }
            }
            return false
        }

        const testpromoteUnconditionally = (menuItem, promotion) => {
            if (promotion.promoteUnconditionally === true) {
                 return true
            }
            if (promotion.promoteUnconditionally === false) {
                if (!this.orderDetails.some(name => name.menuItemId === promotion.menuItemId)) {
                    return true;
                }
            } 
             return false
            }
        

        //console.log(promotions, menuItem,level)

        for (let i = 0; i < promotions.length; i++) {
            const promotion = promotions[i];
            if (promotion.level === level) {
                const validMenuItem = testMenuItem(menuItem, promotion);
                const validMenuItemCategory = testMenuItemCategory(menuItem, promotion);
                const promoteUnconditionally = testpromoteUnconditionally(menuItem, promotion, this.orderDetails);
                //console.log(promotion,validMenuItem,validMenuItemCategory,promoteUnconditionally)
                if (validMenuItem && validMenuItemCategory && promoteUnconditionally) {
                    tempArray.push(promotion);
                }
            } 
        }
        return tempArray
        
    }

}
export default OrderHeader;