const GetMenuId = (menuSchedules) => {
    var currentDate;
    var startDate;
    var endDate;
    
    const setDates = (schedule) => {
        currentDate = new Date();
        startDate = new Date();
        endDate = new Date();

        if (schedule.startTime !== null) {
            startDate.setHours(schedule.startTime.slice(0, 2))
            startDate.setMinutes(schedule.startTime.slice(3, 5))
            startDate.setSeconds(0)
        } 

        if (schedule.startDate !== null) {
            startDate.setFullYear(schedule.startDate.slice(0, 4))
            startDate.setMonth(schedule.startDate.slice(5, 7))
            startDate.setDate(schedule.startDate.slice(8, 9))
        }

        if (schedule.endTime !== null) {
            endDate.setHours(schedule.endTime.slice(0, 2))
            endDate.setMinutes(schedule.endTime.slice(3, 5))
            endDate.setSeconds(0)
        } 

        if (schedule.endDate !== null) {
            endDate.setFullYear(schedule.endDate.slice(0, 4))
            endDate.setMonth(schedule.endDate.slice(5, 7))
            endDate.setDate(schedule.endDate.slice(8, 9))
        }
    }

    
    const checkMenu = (dateType, timeType) => {
        for (let i=0; i < menuSchedules.length; i++) {
            if (menuSchedules[i].dateSchedule === dateType && menuSchedules[i].timeSchedule === timeType) {
                if (dateType === "A" && dateType === "A") return menuSchedules[i];
                setDates(menuSchedules[i]);
                if (currentDate >= startDate && currentDate <= endDate) {
                    return menuSchedules[i];
                }
            }
        }
    }


    const CheckSameDay = () => {
        for (let i=0; i < menuSchedules.length; i++) {
            if (menuSchedules[i].startDate === menuSchedules[i].endDate && menuSchedules[i].startDate !== null) {
                let tempCurrentDate = new Date();
                let tempMonth = tempCurrentDate.getMonth();
                if (tempMonth < 10) tempMonth = '0' + tempMonth;
                let tempDate = tempCurrentDate.getDate();
                if (tempDate < 10) tempDate = '0' + tempDate;
                let tempString = tempCurrentDate.getFullYear() + '-' + tempMonth + '-' + tempDate;
                if (menuSchedules[i].startDate === tempString) return menuSchedules[i];
            }
        }
    }


    if (CheckSameDay() !== undefined) return CheckSameDay();
    if (checkMenu("R", "R") !== undefined) return checkMenu("R", "R");
    if (checkMenu("R", "A") !== undefined) return checkMenu("R", "A");
    if (checkMenu("A", "R") !== undefined) return checkMenu("A", "R");
    if (checkMenu("A", "A") !== undefined) return checkMenu("A", "A");
    if (menuSchedules.length === 0) return {menuId:"",description:""};
    return menuSchedules[0].menuScheduleId;

}


export default GetMenuId;