import React, { useEffect } from "react";


const Concept = (props) => {
    const { singleConcept, selectedConcepts, onConceptChange, concept } = props;
    
    const conceptChangeHandler = () => {
        if (((selectedConcepts.includes(concept) && selectedConcepts.length > 1) || !selectedConcepts.includes(concept)) && onConceptChange) {
            onConceptChange(concept.conceptId);
        }
    }

    useEffect(() => {
        if (singleConcept) conceptChangeHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            {!singleConcept &&
                <div className={selectedConcepts.includes(concept) ? "conceptDiv selectedConcept" : "conceptDiv"} onClick={conceptChangeHandler}>
                    <div className="conceptImgDiv"><img style={window.location.href.includes("aaxab") ? {filter: "invert(1)"} : null} src={window.innerWidth < 600 ? concept.logoUrl : concept.thumbNailUrl} onLoad={conceptChangeHandler} alt="" /></div>
                </div>
            }
            {singleConcept &&
                <div><img className="singleConceptLogo" style={window.location.href.includes("aaxab") ? {filter: "invert(1)"} : null} src={window.innerWidth < 600 ? concept.logoUrl : concept.thumbNailUrl} alt=""/></div>
            }  
        </>
    ) 
}


export default Concept;

/*
<Card className="buttonContainer" onClick={conceptChangeHandler}>
    <Card.Img src={concept.logoUrl} onLoad={conceptChangeHandler}/>
    <Card.Body className={selectedConcepts.includes(concept) ? "selected1" : null}><p>{concept.conceptName}</p></Card.Body>
</Card> 
*/