import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Logo from './logo.png';
import { useParams } from "react-router";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const Error = (props) => {
    const { code } = props;
    const { errorcode } = useParams();
    const [hoverError, setHoverError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
      if (code === 404) setHoverError("Page not found.");
      if (code === 500) setHoverError("Internal server error.");
      if (errorcode === "noconcepts") setHoverError("No Concepts Assigned To The Location");
      if (code === "receipt") setHoverError(errorcode);
    }, [code, errorcode])
    

    return (
        <div style={{width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center"}}>
            <div style={{padding: "48px", maxWidth: "500px"}}>
                <Card>
                    {/* logo */}
                    <Card.Header className="pt-4 pb-4 text-center bg-primary">
                        <Link to="/">
                            <span>
                                <img src={Logo} alt="" height="18" title={hoverError} />
                            </span>
                        </Link>
                    </Card.Header>
                    <Card.Body className="p-4">
                        <div className="text-center">
                            {code === 404 &&
                                <>
                                    <h1 className="text-error">Error Code 404</h1>
                                    <h4 className="text-uppercase text-danger mt-3">Page Not Found</h4>
                                    <p className="text-muted mt-3">
                                        It's looking like you may have taken a wrong turn. Don't worry... it happens
                                        to the best of us. Here's a little tip that might help you get back on
                                        track.
                                    </p>
                                </>
                            }
                            {code === 500 &&
                                <>
                                    <h1 className="text-error">Error Code 500</h1>
                                    <h4 className="text-uppercase text-danger mt-3">Server Error</h4>
                                    <p className="text-muted mt-3">
                                        We're sorry, it looks like something went worng on our end.
                                        Please try again in a couple minutes. If the problem persists,
                                        please contact an administrator.
                                    </p>
                                </>
                            }
                            {code === "receipt" && 
                                <>
                                    <h1 className="text-error">Error</h1>
                                    <h4 className="text-uppercase text-danger mt-3">Could not submit feedback</h4>
                                    <p className="text-muted mt-3">
                                        We're sorry, it looks like something went worng on our end.
                                        Please try again in a couple minutes.
                                    </p>
                                </>
                            }
                            {errorcode !== "" && code === undefined &&
                                <>
                                    <h1 className="text-error">Error</h1>
                                    <p className="text-muted mt-3">
                                        We're sorry, it looks like something went worng on our end.
                                        Please try again in a couple minutes. If the problem persists,
                                        please contact an administrator.
                                    </p>
                                </>
                            }
                            
                            <div onClick={() => navigate(-1)} className="btn btn-info mt-3">Try Again</div>
                        </div>
                    </Card.Body>
                </Card>
            </div>

            <div>2021 - 2022 © Kluck - getkluck.com</div>
        </div>
    );
};


export default Error ;