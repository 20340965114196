import { createStore } from 'redux';
import { ActionTypes } from './constants';
import axios from 'axios';
import OrderHeader from '../models/OrderHeader';


const INIT_STATE = {
    concepts: new Map(), userCache: new Map(), modifierGroups: {modifiers: [], connectors: [], counter: 0}, modifierGroupMap: {}, 
    modifierConnectorsMap: {}, clientId: "", accountData: {firstName: "", lastName: "", email: ""}, loggedIn: false, 
    orderHeader: {orderDetails: [], total: 0}, cartAmount: 0, data86: [], cartUpdate: 0,receiptData: {} };

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}

const reducer = (state = INIT_STATE, action) => {
    //console.log(state)
    const payload = action.payload;

    switch (action.type) {
        case ActionTypes.ADD_RECEIPT_DATA:
            state.receiptData = payload;
            return{...state}
        case ActionTypes.ADD_CONCEPT:
            const conceptObj = {conceptId: payload.conceptId, conceptName: payload.conceptName, menus: payload.menus, thumbNailUrl: payload.thumbNailUrl, logoUrl: payload.logoUrl};
            state.concepts.set(payload.conceptId , conceptObj);
            return { ...state, concepts: new Map(state.concepts)}
        case ActionTypes.ADD_MODIFICATION_GROUP:
            payload.modifiers.forEach(element => state.modifierGroups.modifiers.push(element));
            payload.connectors.forEach(element => state.modifierGroups.connectors.push(element));
            
            state.modifierGroups.counter += 1;

            if (state.modifierGroups.counter === state.concepts.size) {
                for(var i=0;i < state.modifierGroups.modifiers.length;i++) {
                    state.modifierGroupMap[state.modifierGroups.modifiers[i].modifierGroupId] = state.modifierGroups.modifiers[i];
                }
                for(var x = 0; x < state.modifierGroups.connectors.length; x++) {
                    const modifierGroupConnector = state.modifierConnectorsMap[state.modifierGroups.connectors[x].menuItemId];
                    if (modifierGroupConnector === undefined) {
                        state.modifierConnectorsMap[state.modifierGroups.connectors[x].menuItemId] = [];
                    }
                    state.modifierConnectorsMap[state.modifierGroups.connectors[x].menuItemId].push(state.modifierGroups.connectors[x]);  
                }
            }
            return {...state}
        case ActionTypes.ADD_86:
            let tempData86 = {};
            for (let i = 0; i < payload.data.length; i++) {
                tempData86[payload.data[i].menuItemId] = payload.data[i];
            }
            state.data86 = tempData86;
            return {...state}
        case ActionTypes.ADD_CLIENT_ID:
            state.clientId = payload.clientId;
            return{ ...state }
        case ActionTypes.ADD_ACCOUNT_DATA:
            state.accountData = payload.accountData;
            if (!state.loggedIn) state.loggedIn = true;
            return{ ...state }
        case ActionTypes.CREATE_CART:
            return{ ...state, orderHeader: payload.cart }
        case ActionTypes.UPDATE_CART:
            const cart = new OrderHeader();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + getCookie("token");
            axios.get("https://guest-rest-development.getkluck.com/pos/orderForTable/" + payload.tablecode)
                .then((response) => {
                    let newData = response.data;

                    cart.address1 = newData.address1;
                    cart.address2 = newData.address2;
                    cart.authorizedEmployeeId = newData.authorizedEmployeeId;
                    cart.authorizedTimestamp = newData.authorizedTimestamp;
                    cart.city = newData.city;
                    cart.clientId = newData.clientId;
                    cart.compAmount = newData.compAmount;
                    cart.compGroupId = newData.compGroupId;
                    cart.compNote = newData.compNote;
                    cart.compReasonId = newData.compReasonId;
                    cart.compTotal = newData.compTotal;
                    cart.cookingStatus = newData.cookingStatus;
                    cart.guestInfoDisplayed = newData.guestInfoDisplayed;
                    cart.guestName = newData.guestName;
                    cart.locationId = newData.locationId;
                    cart.menuId = newData.menuId;
                    cart.message = newData.message;
                    cart.notes = newData.notes;
                    cart.numberOfGuests = newData.numberOfGuests;
                    cart.orderDetails = newData.orderDetails;
                    cart.orderEmployees = newData.orderEmployees;
                    cart.orderHeaderId = newData.orderHeaderId;
                    cart.orderModeId = newData.orderModeId;
                    cart.orderSourceId = newData.orderSourceId;
                    cart.orderTypeId = newData.orderTypeId;
                    cart.phoneNumber = newData.phoneNumber;
                    cart.postalCode = newData.postalCode;
                    cart.promiseTime = newData.promiseTime;
                    cart.properties = newData.properties;
                    cart.salesTax = newData.salesTax;
                    cart.seatingAreaId = newData.seatingAreaId;
                    cart.serverName = "Dine In"
                    //cart.serverName = newData.serverName; 
                    cart.state = newData.state;
                    cart.status = newData.status;
                    cart.subTotal = newData.subTotal;
                    cart.tableName = newData.tableName;
                    cart.taxable = newData.taxable;
                    cart.terminalName = newData.terminalName;
                    cart.timeStamp = newData.timeStamp;
                    cart.tip = newData.tip;
                    cart.tipPercent = newData.tipPercent;
                    cart.total = newData.total;
                    cart.userId = newData.userId;
                    cart.voidReasonId = newData.voidReasonId;
                })
                state.orderHeader = cart;
            return{ ...state }
        case ActionTypes.UPDATE_CART_AMOUNT:
            state.cartAmount = payload.cartAmount;
            return{ ...state }
        case ActionTypes.CART_UPDATE:
            state.cartUpdate = state.cartUpdate + 1;
            return{ ...state }
        default:
            return {...state}
    } // switch
};

 
export function configureStore(initialState) {
    let store;

    if (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']) {
        store = createStore(reducer, initialState,  window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']);
    } else {
        store = createStore(reducer, initialState, );
    }
    return store;
}