import QuickResponse from "../../pages/quickResponse/QuickResponse";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";
import { ActionTypes } from "../../redux/constants";
import GetMenuId from "../menuManagement/getMenuId/GetMenuId";
import OrderHeader from '../../models/OrderHeader';
import axios from "axios";
import Account from "../account/Account";

//import '../../Custom-KluckKafe.css';
//import '../../Custom-Kojo.css';
//import '../../Custom-SoFresh.css';


const LoadingDi = (props) => {
    const { oh } = props;
    const { tablecode, orderHeaderId } = useParams();
    const dispatch = useDispatch();
    const [concepts, setConcepts] = useState(0);
    const cart = new OrderHeader();
    const { loggedIn } = useSelector((state) => ({loggedIn: state.loggedIn}));

    const login = () => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + getCookie("token");
        if (oh) {
            console.log(orderHeaderId)
            axios.get("https://guest-rest-development.getkluck.com/orderheaders/" + orderHeaderId)
            .then((response) => {
                console.log(response)
                //setOrderHeader(response.data);
            }) 
            .catch((error) => console.log("ERROR:" + error));
        } else {
            axios.get("https://guest-rest-development.getkluck.com/pos/orderForTable/" + tablecode)
            .then((response) => {
                setOrderHeader(response.data);
                getData(response.data.locationId);
            }) 
            .catch((error) => console.log("ERROR:" + error));
        }
    }

    const setOrderHeader = (newData) => {
        cart.address1 = newData.address1;
        cart.address2 = newData.address2;
        cart.authorizedEmployeeId = newData.authorizedEmployeeId;
        cart.authorizedTimestamp = newData.authorizedTimestamp;
        cart.city = newData.city;
        cart.clientId = newData.clientId;
        cart.compAmount = newData.compAmount;
        cart.compGroupId = newData.compGroupId;
        cart.compNote = newData.compNote;
        cart.compReasonId = newData.compReasonId;
        cart.compTotal = newData.compTotal;
        cart.cookingStatus = newData.cookingStatus;
        cart.guestInfoDisplayed = newData.guestInfoDisplayed;
        cart.guestName = newData.guestName;
        cart.locationId = newData.locationId;
        cart.menuId = newData.menuId;
        cart.message = newData.message;
        cart.notes = newData.notes;
        cart.numberOfGuests = newData.numberOfGuests;
        cart.orderDetails = newData.orderDetails;
        cart.orderEmployees = newData.orderEmployees;
        cart.orderHeaderId = newData.orderHeaderId;
        cart.orderModeId = newData.orderModeId;
        cart.orderSourceId = newData.orderSourceId;
        cart.orderTypeId = newData.orderTypeId;
        cart.phoneNumber = newData.phoneNumber;
        cart.postalCode = newData.postalCode;
        cart.promiseTime = newData.promiseTime;
        cart.properties = newData.properties;
        cart.salesTax = newData.salesTax;
        cart.seatingAreaId = newData.seatingAreaId;
        cart.serverName = "Dine In"
        //cart.serverName = newData.serverName; 
        cart.state = newData.state;
        cart.status = newData.status;
        cart.subTotal = newData.subTotal;
        cart.tableName = newData.tableName;
        cart.taxable = newData.taxable;
        cart.terminalName = newData.terminalName;
        cart.timeStamp = newData.timeStamp;
        cart.tip = newData.tip;
        cart.tipPercent = newData.tipPercent;
        cart.total = newData.total;
        cart.userId = newData.userId;
        cart.voidReasonId = newData.voidReasonId;
        dispatch({type: ActionTypes.CREATE_CART, payload: {cart: cart}});
    }
    
    const getData = (locationId) => {
        //axios.get("https://guest-rest-development.getkluck.com/unsecure/locationConcepts/location/" + locationId)
        axios.get("https://guest-rest-development.getkluck.com/unsecure/quickresponse/baxab")
            .then((response) => {
                let locationData = response.data;
                setConcepts(locationData.quickResponseConcepts.length);
                dispatch({type: ActionTypes.ADD_CLIENT_ID, payload: {clientId: locationData.clientId}});
                //getCss(locationData.clientId);
                axios.get("https://guest-rest-development.getkluck.com/unsecure/menuSchedules/" + locationData.locationId)
                    .then((response) => {
                        let menuSchedules = response.data;
                        locationData.quickResponseConcepts.forEach(concept => {
                            axios.get("https://guest-rest-development.getkluck.com/unsecure/menus/concept/" + concept.concept.conceptId)
                                .then((response) => getMenu(response.data, menuSchedules, concept.concept, locationData.locationId))
                                .catch((error) => console.log("ERROR:" + error));
                        });  
                    })
                    .catch((error) => console.log("ERROR:" + error));
            })
            .catch((error) => console.log("ERROR:" + error));
    }

    /*
    const getCss = (clientId) => {
        let head = document.getElementsByTagName('HEAD')[0];
        let link = document.createElement('link');
        link.rel = 'stylesheet'; 
        link.type = 'text/css';
        link.href = "https://kluck.s3.amazonaws.com/" + clientId.toUpperCase() + "/CSS/web.css"; 
        head.appendChild(link);
    }
    */

    const getMenu = (menus, menuSchedules, concept, locationId) => {
        let tempArray = [];
        for (let i = 0; i < menus.length; i++) {
            for (let k = 0; k < menuSchedules.length; k++) {
                if (menus[i].menuId === menuSchedules[k].menuId) tempArray.push(menuSchedules[k]);
            }
        }

        let menuId;
        if (tempArray.length > 0) menuId = GetMenuId(tempArray).menuId;
        else menuId = menus[0].menuId;
        if (menuId !== "") {
            axios.get("https://guest-rest-development.getkluck.com/unsecure/menus/" + menuId + "/" + locationId)
                .then((response) => {
                    let data = response.data;
                    dispatch({type: ActionTypes.ADD_CONCEPT, payload: {conceptId: concept.conceptId, conceptName: concept.conceptName, menus: data, thumbNailUrl: concept.thumbNailUrl, logoUrl: concept.logoUrl}});
                    axios.get("/unsecure/menuItemLocations/location/" + locationId)
                        .then((response) => dispatch({type: ActionTypes.ADD_86, payload: {data: response.data}}))
                    let tempMods;
                    axios.get("https://guest-rest-development.getkluck.com/unsecure/modifierGroups/concept/" + concept.conceptId)
                        .then((response) => {
                            tempMods = response.data;
                            axios.get("https://guest-rest-development.getkluck.com/unsecure/modifierGroupConnector/concept/" + concept.conceptId)
                                .then((response) => dispatch({type: ActionTypes.ADD_MODIFICATION_GROUP, payload: {modifiers: tempMods, connectors: response.data}}))
                                .catch((error) => console.log("ERROR:" + error));
                        })
                        .catch((error) => console.log("ERROR:" + error));
                })
                .catch((error) => console.log("ERROR:" + error));
        }
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    useEffect(() => {
        if (getCookie("token").length > 0 && getCookie("accountData").length > 0) {
            let data = JSON.parse(getCookie("accountData"));
            dispatch({type:ActionTypes.ADD_ACCOUNT_DATA, payload: {accountData: data}});
            login();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    


    return (
        <>
            {!loggedIn && <Account modalOnly={true} login={login} />}
            {loggedIn && <QuickResponse oo={true} concepts={concepts} />}
            <link rel="stylesheet" type="text/css" href="/Custom-KluckKafe.css" />
            <style>@import url('https://fonts.googleapis.com/css?family=Roboto')</style>
            <style>@import url('https://fonts.googleapis.com/css?family=Sanchez')</style>
            <style>@import url(https://fonts.googleapis.com/css?family=Open+Sans)</style>
        </>
    )
}


export default LoadingDi;