import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import Menu from "../../components/menuManagement/menu/Menu";
import ConceptSelector from "./ConceptSelector";
import Cart from "../../components/cart/Cart";
import Account from "../../components/account/Account";
import BottomBar from "./BottomBar";
import Share from "./Share";
import { OverlayTrigger, Popover } from 'react-bootstrap';


const QuickResponse = (props) => {
    const oo = props.oo;
    const [selectedConcepts, setSelectedConcepts] = useState([]);
    const [count, setCount] = useState(0);
    const [numberOfConcepts, setNumberOfConcepts] = useState(0);
    const { concepts } = useSelector((state) => ({concepts: state.concepts}));
    
    const onConceptChangeHandler = (concept) => {
        if (count === numberOfConcepts) {
            setSelectedConcepts([concept]);
        } else {
            if (selectedConcepts.indexOf(concept) !== -1) {
                setSelectedConcepts(selectedConcepts.filter(item => item.conceptId !== concept.conceptId));
            } else setSelectedConcepts([...selectedConcepts, concept]);
        }
        if (count < numberOfConcepts + 1) {
            let tempCount = count;
            setCount();
            setCount(tempCount + 1);
        }
    }

    useEffect(() => {
        setNumberOfConcepts(concepts.size)
    }, [concepts])
    
    const [inputValue, setInputValue] = useState("");
    
    function debounce(callback, interval) {
        let debounceTimeoutId;
    
        return function(...args) {
          clearTimeout(debounceTimeoutId);
          debounceTimeoutId = setTimeout(() => callback.apply(this, args), interval);
        };
    }

    const [currentPage, setCurrentPage] = useState("Home");
    const changePage = (page) => setCurrentPage(page);

    const deleteInputHandler = () => {
        setInputValue("");
        document.getElementById("searchInput").value = "";
    }

    const [radioValue, setRadioValue] = useState("FOOD");

    const [glutenFilter, setGlutenFilter] = useState(false);
    const [dairyFilter, setDairyFilter] = useState(false);
    const [veganFilter, setVeganFilter] = useState(false);
    const [vegetarianFilter, setVegetarianFilter] = useState(false);

    useEffect(() => {
        document.getElementById("sectionContainer").scrollTop = 0;
    // eslint-disable-next-line no-use-before-define
    }, [inputValue, radioValue, glutenFilter, dairyFilter, veganFilter, vegetarianFilter])

    const popover = (
        <Popover id="popover-basic">
            <Popover.Body style={{backgroundColor: "#2e3133", borderRadius: "8px"}}>
                <div>
                    <div className={glutenFilter ? "navBarLink active" : "navBarLink"} onClick={() => setGlutenFilter(!glutenFilter)} style={{marginLeft: "0"}}>GLUTEN FREE</div>
                    <div className={dairyFilter ? "navBarLink active" : "navBarLink"} onClick={() => setDairyFilter(!dairyFilter)} style={{marginLeft: "0"}}>DAIRY FREE</div>
                    <div className={veganFilter ? "navBarLink active" : "navBarLink"} onClick={() => setVeganFilter(!veganFilter)} style={{marginLeft: "0"}}>VEGAN</div>
                    <div className={vegetarianFilter ? "navBarLink active" : "navBarLink"} onClick={() => setVegetarianFilter(!vegetarianFilter)} style={{marginLeft: "0"}}>VEGETARIAN</div>
                </div>
            </Popover.Body>
        </Popover>
    )


    return (
        <div className="pageContainer">  
            <div className="menuTitleDiv" id="menuTitleDiv">
                <ConceptSelector onConceptChange={onConceptChangeHandler} selectedConcepts={selectedConcepts} />
                <div className="menuNavDiv">
                    <div className="searchBar">
                        {inputValue === "" &&
                            <svg viewBox="0 0 20 20">
                                <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                            </svg>
                        }
                        {inputValue !== "" &&
                            <svg viewBox="0 0 20 20" onClick={() => deleteInputHandler()}>
                                <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
                            </svg>
                        }
                        <input onChange={debounce((e) => setInputValue(e.target.value), 500)} id="searchInput" type="text" placeholder="Search" autoComplete="off" />
                    </div>
                    {oo && <Account accountMobile={false} changePage={changePage} />}
                    {oo && <Cart cartMobile={false} changePage={changePage} />}
                </div>
            </div>
            <div className="menuFilterContainer">
                <div className="menuFilterDiv">
                    <div>
                        {selectedConcepts.length > 0 &&
                            selectedConcepts[0].menus.section.children.sort( (a,b) => {return a.sequence - b.sequence}).map((section, index) => {
                                return (
                                    <div
                                        className={radioValue === section.sectionName ? "navBarLink active" : "navBarLink"}
                                        style={{margin: "0 12px"}}
                                        key={index}
                                        onClick={() => setRadioValue(section.sectionName)}
                                    >{section.sectionName}</div>
                                )
                            })
                        }
                    </div>
                    <OverlayTrigger trigger="click" placement="auto" overlay={popover} rootClose="true">
                        <svg style={{width: "20px", cursor: "pointer", margin: "0 12px"}} viewBox="0 0 24 24">
                            <path fill="white" d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z" />
                        </svg>
                    </OverlayTrigger>
                </div>
            </div>
            <Menu 
                selectedConcepts={selectedConcepts} 
                sectionFilter={radioValue} 
                inputValue={inputValue} 
                currentPage={currentPage}
                dietFilter={{gluten: glutenFilter, dairy: dairyFilter, vegan: veganFilter, vegetarian: vegetarianFilter}}
            />
            {oo && <BottomBar currentPage={currentPage} changePage={changePage} />}
            {window.location.href.includes("/di/") && <Share firstPerson={true} />}
        </div>
    )
}


export default QuickResponse;