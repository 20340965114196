import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import './Checkout.css';
import './tempCheckout.css';
import axios from 'axios';
import { ButtonGroup, Button } from 'react-bootstrap';
import { ActionTypes } from '../../redux/constants';


const CheckoutDi = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { tablecode } = useParams();
    const { orderHeader } = useSelector((state) => ({orderHeader: state.orderHeader}));
    // eslint-disable-next-line no-unused-vars
    const [deliveryFee, setDeliveryFree] = useState(5);
    const [orderHeader1, setOrderHeader1] = useState({orderDetails: []});

    const formatModifiers = (modifierGroups) => {
        let tempModifierArray = [];
        modifierGroups.forEach(modifierGroup => {
            modifierGroup.modifiers.forEach(modifier => {
                console.log(modifier)
                tempModifierArray.push({
                    //modifier: modifier.modifier,
                    //quantity: modifier.quantity,
                    orderDetailModifierId: "",
                    orderDetailId: "",
                    modifierGroupId: modifier.modifier.modifierGroupId,
                    modifierId: modifier.modifier.modifierId
                })
            });
        });
        return tempModifierArray
    }

    const SubmitOrderHandler = () => {
        /*
        let card = {
            "cardNumber": document.getElementById('number').value,
            "expiryMonth": document.getElementById('exp').value.substring(0, 2),
            "expiryYear": document.getElementById('exp').value.substring(3, 5),
            "cvv": document.getElementById('cvv').value
        }
        */
        console.log(orderHeader)
        let orderObject = {
            properties: orderHeader.properties,
            orderHeaderId: orderHeader.orderHeaderId,
            clientId: orderHeader.clientId,
            menuId: orderHeader.menuId,
            locationId: orderHeader.locationId,
            timeStamp: orderHeader.timeStamp,
            tableName: orderHeader.tableName,
            terminalName: orderHeader.terminalName,
            orderTypeId: orderHeader.orderTypeId,
            orderModeId: orderHeader.orderModeId,
            orderSourceId: orderHeader.orderSourceId,
            numberOfGuests: orderHeader.numberOfGuests,
            orderDetails: 
                orderHeader.orderDetails.map((orderItem) => {
                    let formattedModifiers = [];
                    if (orderItem.modifiers !== undefined) formattedModifiers = formatModifiers(orderItem.modifiers);
                    return (
                        {
                            properties: orderItem.properties,
                            orderDetailId: orderItem.orderDetailId,
                            orderHeaderId: orderItem.orderHeaderId,
                            seatNumber: orderItem.seatNumber,
                            sequence: orderItem.sequence,
                            menuItemId: orderItem.menuItemId,
                            description: orderItem.description,
                            notes: orderItem.notes,
                            quantity: orderItem.quantity,
                            paidQuantity: orderItem.paidQuantity,
                            price: orderItem.price,
                            modifierPrice: orderItem.modifierPrice,
                            compGroupId: orderItem.compGroupId,
                            compReasonId: orderItem.compReasonId,
                            voidReasonId: orderItem.voidReasonId,
                            authorizedEmployeeId: orderItem.authorizedEmployeeId,
                            menuItemOptionId: orderItem.menuItemOptionId,
                            compAmount: orderItem.compAmount,
                            compNote: orderItem.compNote,
                            modifierDescription: orderItem.modifierDescription,
                            orderDetailModifiers: formattedModifiers,
                            totalPrice: orderItem.totalPrice,
                            holdTime: orderItem.holdTime,
                            orderTimeStamp: orderItem.orderTimeStamp,
                            completedTimeStamp: orderItem.completedTimeStamp,
                            hold: orderItem.hold,
                            submited: orderItem.submited,
                            selected: orderItem.selected,
                            status: orderItem.status,
                            abbreviation: orderItem.abbreviation,
                            giftCardNumber: orderItem.giftCardNumber,
                            cvv: orderItem.cvv,
                            cookingStatus: orderItem.cookingStatus
                        }
                    )
                }),
            orderEmployees: 
                orderHeader.orderEmployees.map((employee) => {
                    return (
                        {
                            orderEmployeeId: employee.orderEmployeeId,
                            clientId: employee.clientId,
                            locationId: employee.locationId,
                            employeeId: employee.employeeId,
                            activeEmployee: employee.activeEmployee,
                            orderHeaderId: employee.orderHeaderId,
                            percentage: employee.percentage
                        }
                    )
                }),
            voidReasonId: orderHeader.voidReasonId,
            authorizedEmployeeId: orderHeader.authorizedEmployeeId,
            seatingAreaId: orderHeader.seatingAreaId,
            authorizedTimestamp: orderHeader.authorizedTimestamp,
            compGroupId: orderHeader.compGroupId,
            compReasonId: orderHeader.compReasonId,
            compAmount: orderHeader.compAmount,
            compNote: orderHeader.compNote,
            subTotal: orderHeader.subTotal,
            compTotal: orderHeader.compTotal,
            salesTax: orderHeader.salesTax,
            tip: orderHeader.tip,
            tipPercent: orderHeader.tipPercent,
            total: orderHeader.total,
            guestName: orderHeader.guestName,
            notes: orderHeader.notes,
            message: orderHeader.message,
            serverName: orderHeader.serverName,
            phoneNumber: orderHeader.phoneNumber,
            status: orderHeader.status,
            promiseTime: orderHeader.promiseTime,
            cookingStatus: orderHeader.cookingStatus,
            guestInfoDisplayed: orderHeader.guestInfoDisplayed,
            taxable: orderHeader.taxable,
            address1: orderHeader.address1,
            address2: orderHeader.address2,
            city: orderHeader.city,
            state: orderHeader.state,
            postalCode: orderHeader.postalCode,
            userId: orderHeader.userId,

            seatingTableId: "6A7224C2-BCCA-4445-81C7-8D8F3788DADC"
        }
        
        console.log(orderObject)
        console.log(axios.defaults.headers.common['Authorization'])
        axios.post("/sendOrder", orderObject, {timeout: 20000})
        .then((response) => {
            console.log(response)
        }) 
        .catch((error) => console.log("ERROR:" + error));
    }

    const [payingFor, setPayingFor] = useState("all")
    // options are "select", "all", "split", "mine"
    const [selectedTotal, setSelectedTotal] = useState(0);
    const [selectedArray, setSelectedArray] = useState([]);
    const [selectContinue, setSelectContinue] = useState(false);

    useEffect(() => {
        let tempArray = [];
        for (let i = 0; i < orderHeader.orderDetails.length; i++) {
            if (i === 0 && payingFor === "mine") tempArray.push(true);
            else tempArray.push(false);
        }
        setSelectedArray(tempArray);

        if (payingFor === "split") setSelectedTotal(orderHeader.total / 2);
        if (payingFor === "mine") setSelectedTotal(orderHeader.orderDetails[0].totalPrice);
        if (payingFor === "all") setSelectedTotal(orderHeader.total);
        if (payingFor === "select") setSelectedTotal(0);

        if (payingFor === "select") setSelectContinue(false);
        else setSelectContinue(true);
    }, [orderHeader, payingFor])

    const onSelectHandler = (index) => {
        let tempArray = [];
        for (let i = 0; i < selectedArray.length; i++) {
            if (i === index) tempArray.push(!selectedArray[i]);
            else tempArray.push(selectedArray[i]);
        }
        setSelectedArray(tempArray);

        let tempBoolean = false;
        for (let i = 0; i < tempArray.length; i++) if (tempArray[i]) tempBoolean = true;
        setSelectContinue(tempBoolean);

        let tempPrice = 0;
        for (let i = 0; i < tempArray.length; i++) if (tempArray[i]) tempPrice += orderHeader.orderDetails[i].totalPrice;
        setSelectedTotal(tempPrice);
    }

    useEffect(() => {
        if (orderHeader.orderDetails.length === 0) {
            dispatch({type:ActionTypes.UPDATE_CART, payload: {tablecode: tablecode}});
        }
        let clientId = "AD0467DA-DBBF-4B65-AFC5-91E3AFC077";
        axios.get("/unsecure/verifone/iframeurl/" + clientId)
            .then((response) => {
                let div = document.getElementById('payment_form_container');
                let script = document.createElement('script');
                script.src = response.data.url; 
                div.appendChild(script);
            })
            .catch((response) => console.log(response));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (orderHeader1.orderDetails.length === 0) setOrderHeader1(Object.assign({}, orderHeader));
    }, [orderHeader, orderHeader1])


    return (
        <div className="tContainer1">
            <ButtonGroup  style={{marginTop: "12px"}}>
                <Button style={payingFor !== "all" ? {color: "#D3D3D3"} : null} onClick={() => setPayingFor("all")} variant="secondary">All</Button>
                <Button style={payingFor !== "split" ? {color: "#D3D3D3"} : null} onClick={() => setPayingFor("split")} variant="secondary">Split</Button>
                <Button style={payingFor !== "select" ? {color: "#D3D3D3"} : null} onClick={() => setPayingFor("select")} variant="secondary">Select</Button>
                <Button style={payingFor !== "mine" ? {color: "#D3D3D3"} : null} onClick={() => setPayingFor("mine")} variant="secondary">Mine</Button>
            </ButtonGroup>
            <div className="leftTitle" style={{margin: "24px 24px 0", lineHeight: "48px", fontSize: "3em"}}>Checkout</div>
            <div className="backToMenu" style={{marginBottom: "12px"}} onClick={() => navigate("/di/" + tablecode)}>← Back to Menu</div>
            {payingFor === "select" &&
                <div>Please select the items you'd like to pay for.</div>
            }
            <div className="tContainer2">
                <div className="tContainer3">
                    <div className="cartItemsContainer" style={{height: "100%", margin: "12px 40px", maxHeight: "240px"}}>
                        {
                            orderHeader1.orderDetails.map((item, index1) => {
                                return (
                                    <div 
                                        className="cartItemDiv"
                                        onClick={payingFor === "select" ? () => onSelectHandler(index1) : null}
                                        style={{
                                            alignItems: "center",
                                            padding: "0",
                                            cursor: payingFor === "select" ? "pointer" : "unset",
                                            borderRadius: "8px",
                                            backgroundColor: selectedArray[index1] ? "#CED4DA" : "white"
                                        }} 
                                        key={index1}
                                    >
                                        <div className="cartItemImgDiv" style={{height: "60px"}}>
                                            <div><img style={{cursor: payingFor === "select" ? "pointer" : "unset"}} src={item.menuItem.thumbNailUrl === undefined ? item.image : item.menuItem.thumbNailUrl} alt="" /></div>
                                        </div>
                                        <div className="cartItem" style={{marginRight: "12px"}}>
                                            <div className="cartItemHeader" style={{margin: "0"}}>
                                                <div className="cartItemName" style={{cursor: payingFor === "select" ? "pointer" : "unset"}}>{item.description}</div>
                                                <div className="cartPrice">${item.price.toFixed(2)} x {item.quantity}</div>
                                            </div>
                                            <div className="cartItemModsDiv">
                                                {item.modifiers !== undefined &&
                                                    // eslint-disable-next-line array-callback-return
                                                    item.modifiers.map((modifierGroup, index) => {
                                                        let i = 0;
                                                        modifierGroup.modifiers.forEach(element => i += element.quantity);
                                                        if (i > 0) {
                                                            return (
                                                                <div key={index}>
                                                                    {
                                                                        // eslint-disable-next-line array-callback-return
                                                                        modifierGroup.modifiers.map((modifier, index) => {
                                                                            if (modifier.quantity > 0) {
                                                                                return (
                                                                                    <div className="cartItemMod" key={index}>
                                                                                        <div>
                                                                                            {modifier.modifier.menuItemOptionName !== undefined &&
                                                                                                <div>{modifier.modifier.menuItemOptionName.toUpperCase()}</div>
                                                                                            }
                                                                                            {modifier.modifier.menuItemOptionName === undefined &&
                                                                                                <div>
                                                                                                    {modifier.modifier.modifierName.toUpperCase()}
                                                                                                    {modifier.quantity > 1 &&
                                                                                                        <>{" "}x{modifier.quantity}</>
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        <div>
                                                                                            {modifier.modifier.menuItemOptionName !== undefined &&
                                                                                                <div>
                                                                                                    {modifier.modifier.amount > 0 &&
                                                                                                        <div>(${modifier.modifier.amount.toFixed(2)})</div>
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                            {modifier.modifier.menuItemOptionName === undefined &&
                                                                                                <div>
                                                                                                    {" "}
                                                                                                    {modifier.modifier.amount > 0 &&
                                                                                                        <>
                                                                                                            {modifier.quantity === 1 &&
                                                                                                                <div>(+${modifier.modifier.amount.toFixed(2)})</div>
                                                                                                            }
                                                                                                            {modifier.quantity > 1 &&
                                                                                                                <div>(+${(modifier.modifier.amount * modifier.quantity).toFixed(2)})</div>
                                                                                                            }
                                                                                                        </>
                                                                                                        
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="tContainer3">
                    <div className="coTotalDiv" style={{padding: "0 48px 12px"}}>
                        <div className="coTotalSubDiv">
                            <p>Subtotal</p>
                            <p>${selectedTotal.toFixed(2)}</p> 
                        </div>
                        <div className="coTotalSubDiv">
                            <p>Taxes</p>
                            <p>${(selectedTotal * .07).toFixed(2)}</p>
                        </div>
                        <div className="coTotalSubDiv coTotal">
                            <p>Total</p>
                            <p>${(selectedTotal + (selectedTotal * .07)).toFixed(2)}</p>                        
                        </div>  
                    </div>
                </div>
                <div id="payment_form_container" />
                {selectContinue &&
                    <div onClick={() => SubmitOrderHandler()} style={{backgroundColor: "#CED4DA", marginTop: "12px", border: "1px solid #CED4DA"}} className="itemModalAddToCart"><p>Send Payment</p></div>
                }
                {!selectContinue &&
                    <div style={{backgroundColor: "#CED4DA", marginTop: "12px", border: "1px solid #CED4DA", cursor: "unset"}} className="itemModalAddToCart"><p>Select at least 1 item.</p></div>
                }
            </div>
        </div>
    )
}

export default CheckoutDi;