import { Modal } from 'react-bootstrap';
import { useState } from 'react';


const Favorites = (props) => {
    const { favoritesMobile, currentPage } = props;
    const [show, setShow] = useState(false);

    return (
        <>
            {favoritesMobile &&
                <div className="bottomBarButton" onClick={() => props.changePage("Favorites")}>
                    <svg viewBox="0 0 24 24">
                        <path className={currentPage === "Favorites" ? "currentPage" : null} d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />
                    </svg>
                    <p className={currentPage === "Favorites" ? "currentPage" : null}>Favorites</p>
                </div>
            }
            {!favoritesMobile &&
                <div className="loginIconDiv cartNotMobile" onClick={() => setShow(true)} style={{marginRight: "1em"}}>
                    <svg viewBox="0 0 24 24" style={{transform: "translate(0, 9px)"}}>
                            <path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />
                        </svg>
                </div>
            }
            <Modal show={favoritesMobile ? currentPage === "Favorites" : show}>
                {!favoritesMobile &&
                    <div className="modalX" onClick={() => setShow(false)}>
                        <svg viewBox="0 0 24 24">
                            <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
                        </svg>
                    </div>  
                }
                <p className="modalTitle">Favorites</p>
                
            </Modal>
        </>
    )
}


export default Favorites;