import React from 'react';
import { Col, Badge } from 'react-bootstrap';
import  { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';


const MenuItem = (props) => {
    const { menuItem, showImages, showBadge, conceptName } = props;
    const { data86 } = useSelector((state) => ({data86: state.data86}));
    const [avalabilityData, setAvalabilityData] = useState({available: 1, unlimited: true});
    const readOnly = window.location.href.includes("/qr/");
    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
    })

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize)
    
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    })

    const onClickHandler = () => {
        props.handleShow();
        let tempMenuItem = menuItem;
        if (avalabilityData.unlimited) tempMenuItem.available = 999;
        else tempMenuItem.available = avalabilityData.available;
        props.getMenuItem(tempMenuItem);
        props.getCurrentConceptName(conceptName);
    }

    useEffect(() => {
      if (data86.length !== 0 && data86[menuItem.menuItemId.toUpperCase()] !== undefined) setAvalabilityData(data86[menuItem.menuItemId.toUpperCase()]);
      if (window.location.href.includes("/qr/")) setAvalabilityData({available: 1, unlimited: true})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data86])
    

    return (
        <>
            {dimensions.width <= 600 &&
                <Col className={readOnly ? "menuItemMobile readOnly" : "menuItemMobile"} onClick={avalabilityData.available > 0 ? onClickHandler : null}>
                    <h3 className="itemNameMobile">{menuItem.menuItemName}</h3>
                    <p className="itemDescMobile">{menuItem.menuItemDescription}</p> 
                    {((menuItem.menuItemOptions.length === 0 || props.priceCheck(menuItem.menuItemOptions) === 0) && menuItem.defaultPrice > 0) && 
                        <p className="itemPriceMobile">${menuItem.defaultPrice.toFixed(2)}</p>}
                    {menuItem.diets.filter(diet => diet !== null /* && diet.guestFacing */).length > 0 &&
                        <div className="itemDietDivMobile">
                            {
                                // eslint-disable-next-line array-callback-return
                                menuItem.diets.filter(diet => diet !== null /* && diet.guestFacing */).map((diet, index) => {
                                    return (
                                        <div key={index}>
                                            {diet.url === null &&
                                                <div className="itemDietMobile" key={index}>{diet.dietName}</div>
                                            }
                                            {diet.url !== null &&
                                                <div className="itemDietMobile" key={index}>
                                                    <img src={diet.url} alt="" />
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {menuItem.menuItemOptions.length > 0 &&
                        <div className="itemPriceDivMobile">
                            {
                                menuItem.menuItemOptions.map((option, index) => {
                                    return <p className="itemPriceMobile" key={index}>{option.menuItemOptionName} {option.amount > 0 && <>${option.amount.toFixed(2)}</>}</p>
                                })
                            }
                        </div>
                    }
                </Col>
            }
            {dimensions.width >= 601 &&
                <div className={avalabilityData.available > 0 ? "menuItemContainer" : "menuItemContainer outOfStock"}>
                    <Col className={readOnly ? "menuItem readOnly" : "menuItem"} onClick={avalabilityData.available > 0 ? onClickHandler : null} style={(showImages && menuItem.thumbNailUrl === null) ? {display: "flex", alignItems: "flex-end"} : null}>
                        {(showImages && menuItem.thumbNailUrl !== null) && 
                            <div className="itemImgDivDiv">
                                <div className="itemImgDiv" style={props.checkImgUrl(menuItem.thumbNailUrl)} alt="" />
                            </div>
                        }
                        <div className="menuItemInfoDiv" style={!showImages || menuItem.thumbNailUrl === null ? {borderRadius: "8px"} : {height: "calc(100% - 217px)"}}>
                            <div>
                                <div className="itemDiv">
                                    <p className="itemName">{menuItem.menuItemName}</p>
                                    {showBadge && <Badge className="itemBadge" pill bg="secondary" text="dark">{conceptName}</Badge>}
                                </div>
                                <p className="itemDescMobile">{menuItem.menuItemDescription}</p>
                            </div>
                            {avalabilityData.available > 0 &&
                                <div>
                                    {menuItem.diets.filter(diet => diet !== null /* && diet.guestFacing */).length > 0 &&
                                        <div className="itemDietDivMobile">
                                            {
                                                // eslint-disable-next-line array-callback-return
                                                menuItem.diets.filter(diet => diet !== null /* && diet.guestFacing */).map((diet, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {diet.url === null &&
                                                                <div className="itemDietMobile" key={index}>{diet.dietName}</div>
                                                            }
                                                            {diet.url !== null &&
                                                                <div className="itemDietMobile" key={index}>
                                                                    <img src={diet.url} alt="" />
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    {((menuItem.menuItemOptions.length === 0 || props.priceCheck(menuItem.menuItemOptions) === 0) && menuItem.defaultPrice > 0) && 
                                        <p className="itemPriceMobile">{menuItem.defaultPrice.toFixed(2)}</p>}
                                    {(menuItem.menuItemOptions.length && props.priceCheck(menuItem.menuItemOptions) > 0) > 0 &&
                                        <div className="itemPriceDivMobile">
                                            {
                                                menuItem.menuItemOptions.map((option, index) => {
                                                    return <p className="itemPriceMobile" key={index}>{option.menuItemOptionName} {option.amount > 0 && <>{option.amount.toFixed(2)}</>}</p>
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            {avalabilityData.available === 0 &&
                                <div className="itemPriceMobile">OUT OF STOCK</div>
                            }
                        </div>
                    </Col>
                </div>
            }
        </>
    )
}


export default MenuItem;