import { useState, useEffect } from "react";
import { Row, Modal, ToggleButton, Accordion, Form } from 'react-bootstrap';
import MenuItem from '../menuItem/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { ActionTypes } from '../../redux/constants';
import RadioButton from './RadioButton';
import CheckBox from './CheckBox';


const Section = (props) => {
    const { children, conceptName, conceptsLength, inputValue, currentPage, dietFilter } = props;
    const { level } = props;
    const [fontSize, setFontSize] = useState("24");
    const { orderHeader } = useSelector((state) => ({orderHeader: state.orderHeader}));
    const { data86 } = useSelector((state) => ({data86: state.data86}));
    const dispatch = useDispatch();

    useEffect( ()=> {
        const fontString = (Number(fontSize) - (level * 2)).toString();
        setFontSize(fontString + "px");
    },[level, fontSize]);

    const checkInputValue = (section) => {
        let boolean;
        
        const forEach = (section) => {
            section.sectionItems.forEach(menuItem => {
                if (menuItem.menuItem.menuItemName !== undefined) {
                    if (menuItem.menuItem.menuItemName.toLowerCase().includes(inputValue.toLowerCase()) || menuItem.menuItem.menuItemDescription.toLowerCase().includes(inputValue.toLowerCase())) {
                        if (checkDietFilter(menuItem.menuItem.diets) && boolean !== true) boolean = true;
                    }
                }
            });
            if (boolean !== true) boolean = false;

            if (section.children.length > 0) {
                section.children.forEach(section => {
                    forEach(section);
                });
            }
        }
        forEach(section);
        return boolean;
    }

    const checkDietFilter = (diets) => {
        let filterCount = 0;
        let count = 0;

        if (dietFilter !== undefined) {
            if (dietFilter.gluten) { filterCount += 1; diets.forEach(diet => {if (diet !== null && diet.dietName === "Gluten Free") count += 1}); }
            if (dietFilter.dairy) { filterCount += 1; diets.forEach(diet => {if (diet !== null && diet.dietName === "Dairy Free") count += 1}); }
            if (dietFilter.vegan) { filterCount += 1; diets.forEach(diet => {if (diet !== null && diet.dietName === "Vegan") count += 1}); }
            if (dietFilter.vegetarian) { filterCount += 1; diets.forEach(diet => {if (diet !== null && diet.dietName === "Vegetarian") count += 1}); }
        }

        if (count === filterCount) return true;
        else return false
    }

    /* MODAL */
    
    const readOnly = window.location.href.includes("/qr/");
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(0);
    const { modifierConnectorsMap } = useSelector((state) => ({modifierConnectorsMap: state.modifierConnectorsMap}));
    const { modifierGroupMap } = useSelector((state) => ({modifierGroupMap: state.modifierGroupMap}));
    const [show, setShow] = useState(false);
    const [optionRadioValue, setOptionRadioValue] = useState("");
    const { editMode, editIndex } = props;

    const [menuItem, setMenuItem] = useState({menuItemOptions: [], modifierGroups: []});

    const handleClose = () => {
        setShow(false);
        setOptionRadioValue("");
    }

    const handleShow = () => {
        setQuantity(1);
        setModifiers([]);
        if (!readOnly) setShow(true);
    }
    
    const checkImgUrl = (url) => {return {backgroundImage: "url(" + url + ")"}}

    const AddToCartHandler = () => {
        dispatch({type:ActionTypes.CART_UPDATE, payload: {}});
        orderHeader.addOrderDetails(menuItem, modifiers, quantity, price, price - modAddition, modAddition, price, currentConceptName, [])
                                 //(menuItem, modifiers, quantity, paidQuantity, price, modifierPrice, totalPrice, concept, stations)
        handleClose();
    }

    const addQuantity = () => {
        setQuantity(quantity + 1)
        orderHeader.addQuantOrderDetails(orderHeader.orderDetails[editIndex], editIndex);
    }

    const subQuantity = () => {
        setQuantity(quantity - 1)
        orderHeader.subQuantOrderDetails(orderHeader.orderDetails[editIndex], editIndex);
    }

    const [modifiers, setModifiers] = useState([]); 

    const ModificationHandler = (modifierGroupName, modifierGroupId, modifiers1) => {
        modifiers.forEach((element, index) => {
            if (element.modifierGroupName === modifierGroupName) {
                let tempModifiers = modifiers;
                tempModifiers.splice(index, 1);
                setModifiers(tempModifiers);
            }
        });

        let tempModifiers = modifiers;
        tempModifiers.push({modifierGroupName: modifierGroupName, modifierGroupId: modifierGroupId, modifiers: modifiers1})
        setModifiers(tempModifiers)

        getPrices();
        RequiredCheck();
    }

    // eslint-disable-next-line no-unused-vars
    const [modArray, setModArray] = useState([]);
    const [modAddition, setModAddition] = useState();

    useEffect(() => {
        if (editMode) getPrices(editMenuItem);
        else getPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        getPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuItem])

    const getPrices = (editMenuItem) => {
        setModArray([]);
        let tempArray = [];
        let baseTotal;
        if (editMenuItem === undefined) {
            if (menuItem.menuItemOptions.length > 0) {
                if (menuItem.menuItemOptions[0].amountType === "A") baseTotal = menuItem.defaultPrice + menuItem.menuItemOptions[0].amount;
                else baseTotal = menuItem.menuItemOptions[0].amount;
            }
            else baseTotal = menuItem.defaultPrice;
        } else {
            if (editMenuItem.menuItemOptions.length > 0) {
                if (editMenuItem.menuItemOptions[0].amountType === "A") baseTotal = editMenuItem.defaultPrice + editMenuItem.menuItemOptions[0].amount;
                else baseTotal = editMenuItem.menuItemOptions[0].amount;
            }
            else baseTotal = editMenuItem.defaultPrice;
        }
        let modAdditions = 0;
        let multiplier = 1;
        modifiers.forEach((modiferGroup) => {
          if (modiferGroup.modifiers.length > 0) {
            modiferGroup.modifiers.forEach((modifier) => {
              if (modifier.quantity > 0) {
                switch (modifier.modifier.amountType) {
                  case "A":
                    modAdditions = modAdditions + modifier.modifier.amount * modifier.quantity;
                    tempArray.push(modifier);
                    break;
                  case "M":
                    multiplier = modifier.modifier.amount;
                    tempArray.push(modifier);
                    break;
                  case "R":
                    baseTotal = modifier.modifier.amount;
                    tempArray.push(modifier);
                    break;
                  default:
                    break;
                }
              }
            });
          }
        });
        setPrice(baseTotal * multiplier + modAdditions);
        setModAddition(modAdditions);
        modAdditions = 0;
        setModArray(tempArray);
    }
    

    const findModifierGroupConnectorArray = (menuItemId) => {
        const modifierGroupConnectorArray = modifierConnectorsMap[menuItemId.toUpperCase()];
        return modifierGroupConnectorArray;
    }

    const findModifierGroup = (modifierGroupId) => {
        return modifierGroupMap[modifierGroupId.toLowerCase()];
    }

    const findModifier = (menuItemId) => {
        const modifierGroupConnectorArray = findModifierGroupConnectorArray(menuItemId);
        if (modifierGroupConnectorArray !== undefined) {
            const modifierGroupArray = [];
            for(var i=0; i < modifierGroupConnectorArray.length; i++) {
                const modifierGroupConnector = modifierGroupConnectorArray[i];    
                const modifierGroup = findModifierGroup(modifierGroupConnector.modifierGroupId);
                modifierGroupArray.push(modifierGroup);
            }
            return modifierGroupArray;
        }
        return [];
    }

    const [addToCart, setAddToCart] = useState(false);
    const [required, setRequired] = useState([])

    useEffect(() => {
        setAddToCart(false);
        var tempRequired = [];
        if (menuItem.modifierGroups.length > 0) {
            menuItem.modifierGroups.forEach(modifier => {
                if (modifier.minimum > 0 && modifier.guestFacing) tempRequired.push(modifier.modifierGroupName);
            });
        }
        if (menuItem.menuItemOptions.length > 0) tempRequired.push("Option");

        setRequired(tempRequired);
        if (window.location.href.includes("/di/")) setRequired([]);
        if (window.location.href.includes("/di/")) setAddToCart(true);
        if (tempRequired.length === 0) setAddToCart(true);

        if (editMode) {
            let tempMenuItem = editMenuItem;
            if (Object.keys(data86).length === 0) tempMenuItem.available = 999;
            else if (data86[editMenuItem.menuItemId.toUpperCase()] !== undefined) tempMenuItem.available = data86[editMenuItem.menuItemId.toUpperCase()].available;
            else tempMenuItem.available = 999;
            setMenuItem(tempMenuItem);

            if (orderHeader.orderDetails[editIndex].modifiers !== undefined) {
                orderHeader.orderDetails[editIndex].modifiers.forEach(modifier => {
                    if (modifier.modifierGroupName === "Option") setOptionRadioValue(modifier.modifiers[0].modifier.menuItemOptionName);
                });
            } 
            
            setQuantity(orderHeader.orderDetails[editIndex].quantity);
            if (orderHeader.orderDetails[editIndex].modifiers !== undefined) setModifiers(orderHeader.orderDetails[editIndex].modifiers);
            setShow(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuItem])
    
    const RequiredCheck = () => {
        let count1 = 0;
        modifiers.forEach(modifierGroup => {
            required.forEach(requiredGroup => {
                if (requiredGroup === modifierGroup.modifierGroupName) {
                    let count2 = 0;
                    modifierGroup.modifiers.forEach(modifier => {
                        count2 += modifier.quantity;
                    });
                    if (count2 > 0) count1 += 1;
                }
            });
        });

        if (count1 === required.length) setAddToCart(true); 
        else setAddToCart(false);
    }

    const priceCheck = (options) => {
        if (options.length === 0) return 1;
        let price = 0;
        options.forEach(option => {
            price += option.amount;
        });
        return price;
    }    

    const getMenuItem = (menuItem) => {
        menuItem.modifierGroups = findModifier(menuItem.menuItemId);
        setMenuItem(menuItem);
    }

    const { editMenuItem } = props;
    
    useEffect(() => {
        if (editMenuItem !== undefined) getMenuItem(editMenuItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMenuItem])
    
    const showModalImage = true;

    useEffect(() => {
      setShow(false);
    }, [currentPage])
    
    const [conceptNameState, setConceptNameState] = useState();
    const [currentConceptName, setCurrentConceptName] = useState();
    
    const getCurrentConceptName = (conceptName) => {setCurrentConceptName(conceptName)};

    useEffect(() => {
      setConceptNameState(conceptName);
    }, [conceptName])


    return (
        <>
            {children.length > 0 &&
                // eslint-disable-next-line array-callback-return
                children.map((section, index) => {
                    let showImages = section.showImages;
                    return(
                        <div key={index}>
                            {(section.guestFacing && checkInputValue(section)) && 
                                <div id={section.sectionName} key={index}>       
                                <p className="sectionTitle" style={{fontSize: fontSize}}>{section.sectionName}</p> 
                                <p className="sectionDesc">{section.sectionDescription}</p> 
                                <Row className="selectionItems">
                                    {
                                        // eslint-disable-next-line array-callback-return
                                        section.sectionItems.sort((a,b) => {return a.sequence - b.sequence}).filter(childs => childs.menuItem !== null && childs.menuItem.guestFacing && (childs.menuItem.menuItemName.toLowerCase().includes(inputValue.toLowerCase()) || childs.menuItem.menuItemDescription.toLowerCase().includes(inputValue.toLowerCase())) && checkDietFilter(childs.menuItem.diets)).map((menuItem, index) => {
                                            return (
                                                <MenuItem 
                                                    key={index}
                                                    menuItem={menuItem.menuItem}
                                                    editMode={false}
                                                    showImages={showImages}
                                                    conceptName={level === 1 ? section.conceptName : conceptNameState}
                                                    showBadge={false}
                                                    dietFilter={dietFilter}
                                                    //showBadge={conceptsLength === 1 ? false : true}
                                                    getMenuItem={getMenuItem}
                                                    priceCheck={priceCheck}
                                                    handleShow={handleShow}
                                                    checkImgUrl={checkImgUrl}
                                                    getCurrentConceptName={getCurrentConceptName}
                                                />
                                            ) 
                                        })
                                    }  
                                </Row>
                                <Section 
                                    key={'SECTION' + section.sectionId} 
                                    level={level+1} children={section.children} 
                                    conceptName={level === 1 ? section.conceptName : conceptNameState} 
                                    conceptsLength={conceptsLength} 
                                    inputValue={inputValue} 
                                />
                            </div>
                            }
                        </div>
                    ) 
                })
            }
            <Modal show={show} onHide={handleClose} centered animation={true} backdrop={editMode ? "static" : true}>
                <Modal.Body className="itemModalBody">
                    <div className="modalX" onClick={editMode ? () => props.finishEditHandler() : () => setShow(false)}>
                        <svg viewBox="0 0 24 24">
                            <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
                        </svg>
                    </div>
                        {(showModalImage && menuItem.thumbNailUrl !== null) &&   
                            <div className="itemModalImgDiv">
                                <img src={menuItem.thumbNailUrl} alt="" />
                            </div>
                        }
                        {(showModalImage && menuItem.thumbNailUrl === null) &&   
                            <div className="itemModalImgDivPlaceHolder"></div>
                        }
                    <h1 className="itemModalName">{menuItem.menuItemName}</h1>
                    {menuItem.menuItemDescription !== "" &&
                        <p className="itemModalDesc">{menuItem.menuItemDescription}</p>}
                    <div className="itemModalMods">
                        {(!window.location.href.includes("/di/") && (menuItem.menuItemOptions.length > 0 || menuItem.modifierGroups.filter(group => group.guestFacing).length > 0)) &&
                            <div className="itemModalOptionsDiv">
                                <Accordion defaultActiveKey="0">
                                    {menuItem.menuItemOptions.length > 0 &&
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className="notLastMod">
                                                <div className="modifierHeader">
                                                    Option
                                                </div>
                                                <div className="modifierRequired">
                                                    {("Required")}
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body className="notLastMod">
                                            {
                                                menuItem.menuItemOptions.sort((a,b) => {return a.sequence - b.sequence}).map((option, index) => {
                                                    return (
                                                        <div className="itemModalOptionDiv" key={index}>
                                                            <ToggleButton
                                                                className="itemModalOption"
                                                                id={`radio-${index}`}
                                                                type="radio"
                                                                variant="secondary"
                                                                checked={optionRadioValue === option.menuItemOptionName}
                                                                value={option.menuItemOptionName}
                                                                onChange={() => setOptionRadioValue(option.menuItemOptionName)}
                                                                onClick={() => ModificationHandler("Option", "OptionId", [{modifier: option, quantity: 1}])}
                                                            >
                                                                <div className="itemModalOptionName">
                                                                <Form>
                                                                    <Form.Check 
                                                                        type="radio"
                                                                        checked={optionRadioValue === option.menuItemOptionName} 
                                                                        name={`radio-${index}`}
                                                                        onChange={() => setOptionRadioValue(option.menuItemOptionName)}
                                                                        onClick={() => ModificationHandler("Option", [{modifier: option, quantity: 1}])}
                                                                    />
                                                                </Form>
                                                                <div>{option.menuItemOptionName}</div>
                                                                </div>
                                                                {(option.amountType === "R" && option.amount !== 0) &&
                                                                    <div className="modifierPrice">${option.amount.toFixed(2)}</div>
                                                                } 
                                                                {(option.amountType === "M" && option.amount !== 0) &&
                                                                    <div className="modifierPrice">x{option.amount}</div>
                                                                } 
                                                                {(option.amountType === "A" && option.amount !== 0) &&
                                                                    <div className="modifierPrice">+${option.amount.toFixed(2)}</div>
                                                                } 
                                                            </ToggleButton>
                                                        </div>
                                                    )
                                                })
                                            }
                                            </Accordion.Body> 
                                        </Accordion.Item>
                                    }
                                    
                                </Accordion>
                                {
                                    menuItem.modifierGroups.sort( (a,b) => {return a.sequence - b.sequence} ).filter(childs => childs.guestFacing).map((modifierGroup, index) => {
                                        return (
                                            <div key={index}>
                                                {modifierGroup.uniqueFlag &&
                                                    <RadioButton
                                                        modifierGroup={modifierGroup}
                                                        index={index}
                                                        ModificationHandler={ModificationHandler}
                                                        editMode={editMode}
                                                        editIndex={editIndex}
                                                        lastMod={index === menuItem.modifierGroups.length - 1 ? true : false}
                                                    />
                                                }
                                                {!modifierGroup.uniqueFlag &&
                                                    <CheckBox
                                                        modifierGroup={modifierGroup}
                                                        ModificationHandler={ModificationHandler}
                                                        editMode={editMode}
                                                        editIndex={editIndex}
                                                        lastMod={index === menuItem.modifierGroups.length - 1 ? true : false}
                                                    /> 
                                                }  
                                            </div>
                                        )
                                    }) 
                                }
                            </div>
                        }
                    </div>
                </Modal.Body>
                <div className="itemModalFooter">
                    {editMode &&
                        <div className="itemModalFooterRemove" onClick={() => props.deleteItemHandler(editIndex)} ><p>Remove</p></div>
                    }
                    <div className="itemModalFooterDiv">
                        <div className="itemModalQuantityDiv">
                            {(quantity > 1 && !editMode) && <div className="itemModalMinus" onClick={() => setQuantity(quantity - 1)}>-</div>}
                            {(quantity > 1 && editMode) && <div className="itemModalMinus" onClick={() => subQuantity()}>-</div>}
                            {quantity < 2 && <div className="itemModalMinus" style={{opacity: "0.4"}}>-</div>}
                            <p className="itemModalQuantity" >{quantity}</p>
                            {(quantity < menuItem.available && !editMode) && <div className="itemModalPlus" onClick={() => setQuantity(quantity + 1)}>+</div>}
                            {(quantity < menuItem.available && editMode) && <div className="itemModalPlus" onClick={() => addQuantity()}>+</div>}
                            {quantity > menuItem.available - 1 && <div className="itemModalPlus" style={{opacity: "0.4"}}>+</div>}
                        </div>
                        {!editMode &&
                            <div className={addToCart ? "itemModalAddToCart" : "itemModalAddToCartNot"} onClick={addToCart ? () => AddToCartHandler() : null}>
                                {modifiers.length === 0 && <p>Add to Cart | ${(quantity * menuItem.defaultPrice).toFixed(2)}</p>}
                                {modifiers.length !== 0 && <p>Add to Cart | ${(quantity * price).toFixed(2)}</p>}
                            </div>
                        }
                        {editMode &&
                            <div className="itemModalAddToCart" onClick={() => props.finishEditHandler()}>
                                {modifiers.length === 0 && <p>Close | ${(quantity * menuItem.defaultPrice).toFixed(2)}</p>}
                                {modifiers.length > 0 && <p>Close | ${(quantity * price).toFixed(2)}</p>}
                            </div>
                        }
                    </div>
                </div>
            </Modal> 
        </>
    );
}


export default Section;