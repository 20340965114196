import { useState, useEffect } from 'react';
import { ToggleButton, Accordion, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const CheckBox = (props) => {
    const { modifierGroup, editMode, editIndex, lastMod } = props;
    const [modifiers, setModifiers] = useState([]);
    const { orderHeader } = useSelector((state) => ({orderHeader: state.orderHeader}));

    const [totalQuantity, setTotalQuantity] = useState(0);

    useEffect(() => {
        if (editMode) {
            orderHeader.orderDetails[editIndex].modifiers.forEach(element => {
                if (modifierGroup.modifierGroupName === element.modifierGroupName) setModifiers(element.modifiers);
            });
        } else {
            var tempModifiers = [];
            modifierGroup.modifiers.forEach(element => {
                if (element.guestFacing) tempModifiers.push({modifier: element, quantity: 0});
            });
            setModifiers(tempModifiers);
            props.ModificationHandler(modifierGroup.modifierGroupName, modifierGroup.modifierGroupName, tempModifiers);
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const QuantityChangeHandler = (index, x) => {
        var tempModifiers = modifiers;
        if ((totalQuantity + x) <= modifierGroup.maximum) {
            tempModifiers[index].quantity += x;
            setTotalQuantity(totalQuantity + x);
        }
        setModifiers();
        setModifiers([...modifiers], tempModifiers);
        props.ModificationHandler(modifierGroup.modifierGroupName, modifierGroup.modifierGroupName, modifiers)
    }

    var counter = 1;

    const CounterCheck = (index) => {
        if (counter === 3) {
            counter = 1;
            ClickHandler(index);
        } else counter += 1;
    }

    const ClickHandler = (index) => {
        var tempModifiers = modifiers;

        if (tempModifiers[index].quantity > 0) {
            setTotalQuantity(totalQuantity - tempModifiers[index].quantity);
            tempModifiers[index].quantity = 0;
            setModifiers();
            setModifiers([...modifiers], tempModifiers);
        } else if (modifiers[index].quantity === 0 && totalQuantity !== modifierGroup.maximum) {
            setTotalQuantity(totalQuantity + 1);
            tempModifiers[index].quantity = 1;
            setModifiers();
            setModifiers([...modifiers], tempModifiers);
        }

        props.ModificationHandler(modifierGroup.modifierGroupName, modifierGroup.modifierGroupId, modifiers);
    }

    const [isExpanded, setIsExpanded] = useState([]);

    const toggle = () => {
        if (isExpanded.length > 0) {
            setIsExpanded([]);
        }
        else (
            setIsExpanded([modifierGroup.modifierGroupName])
        )
    }

    useEffect(() => {
        if (modifierGroup.expanded) setIsExpanded([modifierGroup.modifierGroupName])
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])


    return (
        <>
            <Accordion activeKey={isExpanded} >
                <Accordion.Item eventKey={modifierGroup.modifierGroupName}>
                <Accordion.Header onClick={() => toggle()} className={lastMod ? null : "notLastMod"}>
                    <div className="modifierHeader">
                        {(modifierGroup.modifierGroupName)}
                    </div>
                    {modifierGroup.minimum > 0 && 
                        <div className="modifierRequired">
                            {("Required")}
                        </div>}
                    {modifierGroup.maximum > 1 && modifierGroup.uniqueFlag === false &&
                        <div className="modifierChoose">
                            ({("Choose up to ")}{(modifierGroup.maximum)})
                        </div>
                    }
                </Accordion.Header>
                <Accordion.Body className={lastMod ? null : "notLastMod"}> 
                {
                    modifiers.sort( (a,b) => {return a.sequence - b.sequence}).map((modifier, index) => {
                        return (
                            <div className="itemModalOptionDiv" key={index}>
                                <ToggleButton
                                    className="itemModalOption"
                                    key={index}
                                    type="checkbox"
                                    variant="secondary"
                                    checked={modifiers[index].quantity > 0}
                                    onClick={() => CounterCheck(index)}
                                    onChange={() => console.log()}
                                >
                                    <div>
                                        <Form>
                                            <Form.Check 
                                                type="checkbox" 
                                                checked={modifiers[index].quantity > 0} 
                                                onClick={() => CounterCheck(index)}
                                                onChange={() => console.log()}
                                            />
                                        </Form>
                                        <div>{modifier.modifier.modifierName}</div>
                                    </div>
                                    {(modifier.modifier.amountType === "R" && modifier.modifier.amount !== 0) &&
                                        <div className="modifierPrice">${modifier.modifier.amount.toFixed(2)}</div>
                                    } 
                                    {(modifier.modifier.amountType === "M" && modifier.modifier.amount !== 0) &&
                                        <div className="modifierPrice">x{modifier.modifier.amount}</div>
                                    } 
                                    {(modifier.modifier.amountType === "A" && modifier.modifier.amount !== 0) &&
                                        <div className="modifierPrice">+${modifier.modifier.amount.toFixed(2)}</div>
                                    }    
                                </ToggleButton>
                                {(modifiers[index].quantity > 0 && !modifier.modifier.uniqueFlag) &&
                                    <div className="modifierQuantityDiv">
                                        {modifiers[index].quantity > 1 && 
                                            <div className="modifierMinus" onClick={() => QuantityChangeHandler(index, -1)}>-</div>
                                        }
                                        {modifiers[index].quantity < 2 && 
                                            <div className="modifierMinus" style={{opacity: "0.4"}}>-</div>
                                        }
                                        <p className="modifierQuantity" >{modifiers[index].quantity}</p>
                                        {totalQuantity < modifierGroup.maximum && 
                                            <div className="modifierPlus" onClick={() => QuantityChangeHandler(index, 1)}>+</div>
                                        }
                                        {totalQuantity > modifierGroup.maximum - 1 && 
                                            <div className="modifierPlus" style={{opacity: "0.4"}}>+</div>
                                        }
                                    </div>
                                }
                            </div>
                        )
                    })
                }
                </Accordion.Body> 
                </Accordion.Item>
            </Accordion>
        </>
    )
}


export default CheckBox;