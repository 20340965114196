import { Link } from 'react-scroll';


const NavBar = (props) => {
    const { children, inputValue, dietFilter } = props;

    const ScrollIntoView = (index) => {
        let element = document.getElementById("navBarLink" + index);
        let offsetLeft = element.offsetLeft - (window.innerWidth / 2) + (element.offsetWidth / 2) + 9;
        let offsetTop = element.offsetTop - ((window.innerHeight * 0.7) / 2) + (element.offsetHeight / 2) + 100;
        document.getElementById("navBarContainer").scrollTo(offsetLeft, offsetTop);
    }

    const checkInputValue = (section) => {
        let boolean;
        
        const forEach = (section) => {
            section.sectionItems.forEach(menuItem => {
                if (menuItem.menuItem !== null) {
                    if (menuItem.menuItem.menuItemName.toLowerCase().includes(inputValue.toLowerCase()) || menuItem.menuItem.menuItemDescription.toLowerCase().includes(inputValue.toLowerCase())) {
                        if (checkDietFilter(menuItem.menuItem.diets) && boolean !== true) boolean = true;
                    }
                }
            });
            if (boolean !== true) boolean = false;

            if (section.children.length > 0) {
                section.children.forEach(section => {
                    forEach(section);
                });
            }
        }
        forEach(section);
        return boolean;
    }

    const checkDietFilter = (diets) => {
        let filterCount = 0;
        let count = 0;

        if (dietFilter !== undefined) {
            if (dietFilter.gluten) { filterCount += 1; diets.forEach(diet => {if (diet !== null && diet.dietName === "Gluten Free") count += 1}); }
            if (dietFilter.dairy) { filterCount += 1; diets.forEach(diet => {if (diet !== null && diet.dietName === "Dairy Free") count += 1}); }
            if (dietFilter.vegan) { filterCount += 1; diets.forEach(diet => {if (diet !== null && diet.dietName === "Vegan") count += 1}); }
            if (dietFilter.vegetarian) { filterCount += 1; diets.forEach(diet => {if (diet !== null && diet.dietName === "Vegetarian") count += 1}); }
        }

        if (count === filterCount) return true;
        else return false
    }


    return(
        <>
            {/*
            <Link 
                className="backToTop"
                containerId="sectionContainer"
                offset={-999999 }
                spyThrottle={0}
                to={children.length > 0 ? children[0].sectionName : "placeHolderText"}
            >
                <svg className="backToTopSvg" viewBox="0 0 24 24">
                    <path d="M4.08,11.92L12,4L19.92,11.92L18.5,13.33L13,7.83V22H11V7.83L5.5,13.33L4.08,11.92M12,4H22V2H2V4H12Z" />
                </svg>
            </Link>
            */}
            <div className="navBarContainer" id="navBarContainer">
                <div className="navBar" id="navBar">
                    {children.length > 0 &&
                        // eslint-disable-next-line array-callback-return
                        children.sort((a, b) => {return a.sequence - b.sequence}).filter(childs => childs.guestFacing && checkInputValue(childs)).map((child, index) => {
                            return <Link 
                                        className={index === children.length - 1 ? "navBarLink lastLink" : "navBarLink"}
                                        containerId="sectionContainer"
                                        id={"navBarLink" + index}
                                        key={index}
                                        offset={-64}
                                        spy={true}
                                        smooth={true}
                                        duration={0}
                                        spyThrottle={50} 
                                        to={child.sectionName} 
                                        onSetActive={() => ScrollIntoView(index)}
                                    >
                                        {child.sectionName}
                                    </Link>  
                        })
                    }
                </div>
            </div>
        </>
    );
}


export default NavBar;