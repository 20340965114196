import logo from './KluckAnimated.gif';


const Loader = () => {

    return (
        <div className="loader">
            <img style={{height: "100px"}} src={logo} alt="" />
        </div>
    )
}


export default Loader;