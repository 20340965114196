import { useEffect } from "react";
import { useParams } from "react-router";
import './Receipt.css';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import axios from 'axios';
import { useDispatch } from "react-redux";
import { ActionTypes } from "../../redux/constants";

const Receipt = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { receiptId } = useParams();
    useEffect(() => {
        axios.get("https://guest-rest.getkluck.com/unsecure/receipt/" + receiptId)
        .then((response) => { 
            console.log(response)      
            dispatch({type:ActionTypes.ADD_RECEIPT_DATA, payload: response.data})
            let div = document.getElementById('receiptDiv');
            div.innerHTML = response.data.reportData;
        })
        .catch((response) => console.log(response));
    }, [receiptId]);

    return (
        <div style={{display:"flex", justifyContent:"center"}}>
            <div style={{top:"5%",position:"absolute",height:"90%", width:"auto", overflowX:"scroll", outline: "1px solid #c5c7cb"}}>
                <div   id="receiptDiv" ></div>
            </div>
        </div>
    );
}

export default Receipt;


