import { useSelector } from 'react-redux';
import { useParams } from "react-router";
import { useState, useEffect } from 'react';
import './Checkout.css';
import { Modal, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Autocomplete from "react-google-autocomplete";
import GoogleMap from './GoogleMap';
import axios from 'axios';
import Cart from '../../components/cart/Cart';


const Checkout = () => {
    /* LEFT SIDE OF SCREEN */
    
    const [selected, setSelected] = useState(0);
    const [selectedStyle, setSelectedStyle] = useState({transform: "translate(-4.9em, -3.7em)"});

    const changeSelectedStyle = (index) => {
        setSelected(index);
        if (index === 0) setSelectedStyle({transform: "translate(-4.9em, -3.7em)"});
        if (index === 1) setSelectedStyle({transform: "translate(4.95em, -3.7em)"});
    }

    const [address, setAddress] = useState("");

    /* RIGHT SIDE OF SCREEN */

    const navigate = useNavigate();
    const { qrcode } = useParams(); 
    const { orderHeader, clientId } = useSelector((state) => ({orderHeader: state.orderHeader, clientId: state.clientId}));
    // eslint-disable-next-line no-unused-vars
    const [deliveryFee, setDeliveryFree] = useState(5);

    const [tip, setTip] = useState({type: "percent", amount: 20, custom: false});
    const setTips = [15, 20, 25];

    const [customTipShow, setCustomTipShow] = useState(false);
    const [tempTip, setTempTip] = useState({type: "percent", amount: 0, custom: true})
    const [tempTotal, setTempTotal] = useState(orderHeader.total + (orderHeader.total * .07));

    const CustomTipHandler = () => {
        setTip({type: tempTip.type, amount: tempTip.amount, custom: true});
        setCustomTipShow(false);
        setTempTotal(orderHeader.total + (orderHeader.total * .07))
    }

    useEffect(() => {
      if (tempTip.type === "fixed") setTempTotal(orderHeader.total + (orderHeader.total * .07) + tempTip.amount)
      if (tempTip.type === "percent") setTempTotal(orderHeader.total + (orderHeader.total * .07) + (orderHeader.total * .01 * tempTip.amount))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempTip, selected])

    const CustomTipChangeHandler = () => {
        if (isNaN(parseInt(document.getElementById('tipInput').value))) setTempTip ({type: tempTip.type, amount: 0, custom: true})
        else setTempTip({type: tempTip.type, amount: parseFloat(document.getElementById('tipInput').value), custom: true})
    }

    const SubmitOrderHandler = () => {        
        let orderObject = {
            properties: [],
            orderHeaderId: "DD067714-4F53-4FD8-9B82-E441EBEE4367",
            clientId: "63692FE5-2541-9C4E-9501-988FA0414208",
            menuId: "607DC07B-A9D7-4C73-8407-9BBFEA9BFD33",
            locationId: "255DE954-D1B9-4AD7-B934-747B1EA23C63",
            tableName: "B12",
            terminalName: "mainbar",
            orderTypeId: "8F1762C0-6F85-407B-B0B6-8AE1E6FD2957",
            orderModeId: "07EB9BF5-DC27-406E-9B11-692F93BB1CAD",
            orderSourceId: "D1478995-3E6F-478C-B398-92BE5A69D34C",
            numberOfGuests: 4,
            orderDetails:
                orderHeader.orderDetails.map((orderItem) => {
                    return (
                        {
                            properties: orderItem.properties,
                            orderDetailId: orderItem.orderDetailId,
                            orderHeaderId: orderItem.orderHeaderId,
                            seatNumber: orderItem.seatNumber,
                            sequence: orderItem.sequence,
                            modifiers: orderItem.modifiers,
                            menuItemId: orderItem.menuItemId,
                            description: orderItem.description,
                            notes: orderItem.notes,
                            quantity: orderItem.quantity,
                            paidQuantity: orderItem.paidQuantity,
                            price: orderItem.price,
                            modifierPrice: orderItem.modifierPrice,
                            compGroupId: orderItem.compGroupId,
                            compReasonId: orderItem.compReasonId,
                            voidReasonId: orderItem.voidReasonId,
                            authorizedEmployeeId: orderItem.authorizedEmployeeId,
                            menuItemOptionId: orderItem.menuItemOptionId,
                            compAmount: orderItem.compAmount,
                            compNote: orderItem.compNote,
                            modifierDescription: orderItem.modifierDescription,
                            orderDetailModifiers: orderItem.orderDetailModifiers,
                            totalPrice: orderItem.totalPrice,
                            holdTime: orderItem.holdTime,
                            orderTimeStamp: orderItem.orderTimeStamp,
                            completedTimeStamp: orderItem.completedTimeStamp,
                            hold: orderItem.hold,
                            submited: orderItem.submited,
                            selected: orderItem.selected,
                            status: orderItem.status,
                            abbreviation: orderItem.abbreviation,
                            giftCardNumber: orderItem.giftCardNumber,
                            cvv: orderItem.cvv,
                            cookingStatus: orderItem.cookingStatus
                        }
                    )
                })
            ,
            /*
            orderDetails: [
                {
                    properties: [],
                    orderDetailId: "6F6E0A38-6207-448B-A51A-1CC83911274D",
                    orderHeaderId: "DD067714-4F53-4FD8-9B82-E441EBEE4367",
                    seatNumber: 1,
                    sequence: 1,
                    modifiers: [],
                    menuItemId: "E4773390-AE24-4BDC-A890-0194A9169E8B",
                    description: "Espresso - Double",
                    notes: "Sugar On Side",
                    quantity: 1,
                    paidQuantity: 1,
                    price: 14.5,
                    modifierPrice: 0.0,
                    compGroupId: "00000000-0000-0000-0000-000000000000",
                    compReasonId: "00000000-0000-0000-0000-000000000000",
                    voidReasonId: "00000000-0000-0000-0000-000000000000",
                    authorizedEmployeeId: "00000000-0000-0000-0000-000000000000",
                    menuItemOptionId: "00000000-0000-0000-0000-000000000000",
                    compAmount: 0,
                    compNote: "",
                    modifierDescription: "",
                    orderDetailModifiers: [],
                    totalPrice: 14.5,
                    holdTime: "P12D",
                    orderTimeStamp: "2022-04-29T15:04:55.393Z",
                    completedTimeStamp: "2022-04-29T15:04:55.393Z",
                    hold: false,
                    submited: false,
                    selected: false,
                    status: "A",
                    abbreviation: "Espresso",
                    giftCardNumber: "",
                    cvv: "",
                    cookingStatus: "O"
                }
            ],
            */
            orderEmployees: [],
            voidReasonId: "00000000-0000-0000-0000-000000000000",
            authorizedEmployeeId: "00000000-0000-0000-0000-000000000000",
            seatingAreaId: "00000000-0000-0000-0000-000000000000",
            compGroupId: "00000000-0000-0000-0000-000000000000",
            compReasonId: "00000000-0000-0000-0000-000000000000",
            compAmount: -5,
            compNote: "FOF",
            subTotal: 14.5,
            compTotal: 0,
            salesTax: 1.50,            
            tip: 1.00,            
            tipPercent: 0,          
            total: 17.0,          
            guestName: "John Doe",          
            notes: "Black Hair Red Shirt",           
            message: "Printer Failed",           
            serverName: "Ashley",           
            phoneNumber: "+12125551212",       
            status: "O",
            cookingStatus: "O",
            guestInfoDisplayed: true,
            taxable: true,
            address1: "123 Main Street",
            address2: "Suite 123",
            city: "New York",
            state: "NY",
            postalCode: "11803",
            subTotalNonAlcohol: 0
        }
        
        console.log(axios.defaults.headers.common['Authorization'])
        axios.post("/sendOrder", orderObject, {timeout: 20000})
        .then((response) => {
            console.log(response)
        }) 
        .catch((error) => console.log("ERROR:" + error));
    }

    const [paymentType, setPaymentType] = useState("Card");

    useEffect(() => {
        if (paymentType === "Card") {
            //let clientId = "AD0467DA-DBBF-4B65-AFC5-91E3AFC077";
            axios.get("/unsecure/verifone/iframeurl/" + clientId)
            .then((response) => {
                let div = document.getElementById('payment_form_container');
                let script = document.createElement('script');
                script.src = response.data.url; 
                div.appendChild(script);
            })
            .catch((response) => console.log(response));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentType])


    return (
        <div className="coDiv">
            <div className="leftDiv">
                <div className="leftTitle">Checkout</div>
                <div className="backToMenu" onClick={() => navigate("/oo/" + qrcode)}>← Back to Menu</div>
                {window.location.href.includes("/oo/") &&
                    <>
                        <div className="deliveryChoiceDiv">     
                            <p onClick={() => changeSelectedStyle(0)}>Delivery</p>
                            <p onClick={() => changeSelectedStyle(1)}>Pick Up</p>
                        </div>
                        <div className="selected" style={selectedStyle} />
                    </>
                }
                <div className="locationDiv">
                    <p className="locationTitle">Location</p>
                    {selected === 0 &&
                        <div className="addressDiv">
                            <Autocomplete
                                style={{width: "100%"}}
                                onPlaceSelected={(place) => {setAddress(place)}}
                                options={{types: ["address"]}}
                            />
                        </div>}
                    {selected === 1 &&
                        <div className="addressDiv">
                            <p className="address">1289 N Palm Ave Sarasota FL 34236</p>
                        </div>}
                    <GoogleMap address={address} /> 
                    <Cart />
                </div>
            </div>
            <div className="rightDiv">
            <div className="coTotalDiv">
                    <div className="coTotalSubDiv">
                        <p>Subtotal</p>
                        <p>${orderHeader.total.toFixed(2)}</p>
                    </div>
                    <div className="coTotalSubDiv">
                        <p>Taxes</p>
                        <p>${(orderHeader.total * .07).toFixed(2)}</p>
                    </div>
                    {selected === 0 &&
                        <div className="coTotalSubDiv">
                            <p>Delivery Fee</p>
                            <p>${deliveryFee.toFixed(2)}</p>
                        </div>  
                    }
                    <div className="coTotalSubDiv">
                        <div className="tip">
                            <p>Tip&nbsp;</p>
                            {
                                setTips.map(
                                    (child, index) => {
                                        return(
                                            <div key={index}>
                                                {tip.amount === child &&
                                                    <div className="tipEdit tipChosen" onClick={() => setTip({type: "percent", amount: child})}>{child}%</div>
                                                }
                                                {tip.amount !== child &&
                                                    <div className="tipEdit"  onClick={() => setTip({type: "percent", amount: child})}>{child}%</div>
                                                }
                                            </div >
                                        )
                                    }
                                )
                            }
                            {tip.custom &&
                                <div className="tipEdit tipChosen" onClick={() => setCustomTipShow(true)}>Custom</div>
                            }
                            {!tip.custom &&
                                <div className="tipEdit" onClick={() => setCustomTipShow(true)}>Custom</div>
                            }
                            <Modal show={customTipShow} onHide={() => setCustomTipShow(false)} centered animation={true} backdrop="static">
                                <div className="modalX" onClick={() => setCustomTipShow(false)}>
                                    <svg viewBox="0 0 24 24">
                                        <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
                                    </svg>
                                </div>
                                <p className="modalTitle">Custom Tip</p>

                                <div className="modalTipPriceDiv">
                                    <p>Subtotal</p>
                                    <p>${orderHeader.total.toFixed(2)}</p>
                                </div>
                                <div className="modalTipPriceDiv">
                                    <p>Tax</p>
                                    <p>${(orderHeader.total * .07).toFixed(2)}</p>
                                </div>
                                {selected === 0 &&
                                    <div className="modalTipPriceDiv">
                                        <p>Delivery Fee</p>
                                        <p>${deliveryFee.toFixed(2)}</p>
                                    </div>
                                }
                                <div className="modalTipPriceDiv">
                                    <div className="modalTipDiv">
                                        {tempTip.type === "fixed" &&
                                            <p className="fixed tipFalse">$</p>
                                        }
                                        {tempTip.type === "percent" &&
                                            <p className="fixed" onClick={() => setTempTip({type: "fixed", amount: tempTip.amount, custom: true})}>$</p>
                                        }
                                        <input className="tipInput" id="tipInput" type="number" min="1" onChange={CustomTipChangeHandler} />
                                        {tempTip.type === "percent" &&
                                            <p className="percent tipFalse">%</p>
                                        }
                                        {tempTip.type === "fixed" &&
                                            <p className="percent" onClick={() => setTempTip({type: "percent", amount: tempTip.amount, custom: true})}>%</p>
                                        }
                                    </div>
                                    {tempTip.type === "fixed" && <p>${tempTip.amount.toFixed(2)}</p>}
                                    {tempTip.type === "percent" && <p>${(orderHeader.total * .01 * tempTip.amount).toFixed(2)}</p>}
                                </div>
                                {selected === 0 &&
                                    <div className="modalTipPriceDiv modalTipTotal">
                                        <p>Total</p>
                                        <p>{(tempTotal + deliveryFee).toFixed(2)}</p>
                                    </div>
                                }
                                {selected === 1 &&
                                    <div className="modalTipPriceDiv modalTipTotal">
                                        <p>Total</p>
                                        <p>{tempTotal.toFixed(2)}</p>
                                    </div>
                                }
                                <div className="itemModalFooter">
                                    <div className="modalSubmit" onClick={CustomTipHandler}><p>Save</p></div>
                                </div>
                            </Modal>
                        </div>
                        {tip.type === "fixed" &&
                            <p >${tip.amount.toFixed(2)}</p>
                        }
                        {tip.type === "percent" &&
                            <p>${(orderHeader.total * .01 * tip.amount).toFixed(2)}</p>
                        }
                    </div>
                    <div className="coTotalSubDiv coTotal">
                        <p>Total</p>
                        {(tip.type === "fixed" && selected === 0) &&
                            <p>${(orderHeader.total + (orderHeader.total * .07) + deliveryFee + tip.amount).toFixed(2)}</p>
                        }
                        {(tip.type === "percent" && selected === 0) &&
                            <p>${(orderHeader.total + (orderHeader.total * .07) + deliveryFee + (orderHeader.total * .01 * tip.amount)).toFixed(2)}</p>
                        }
                        {(tip.type === "fixed" && selected === 1) &&
                            <p>${(orderHeader.total + (orderHeader.total * .07) + tip.amount).toFixed(2)}</p>
                        }
                        {(tip.type === "percent" && selected === 1) &&
                            <p>${(orderHeader.total + (orderHeader.total * .07) + (orderHeader.total * .01 * tip.amount)).toFixed(2)}</p>
                        }
                        
                    </div>  
                </div>
                <div className="paymentDiv">
                    <p className="paymentTitle">Payment</p>
                    <ButtonGroup variant="outline-secondary" title={paymentType} style={{marginBottom: "12px", width: "100%"}}>
                        <ToggleButton variant="secondary" onClick={() => setPaymentType("Card")} style={paymentType !== "Card" ? {color: "#D3D3D3", fill: "#D3D3D3", flex: "1 0 33.33%", whiteSpace: "nowrap"} : {flex: "1 0 33.33%", whiteSpace: "nowrap"}}>
                            Card
                            <svg style={{width: "15px", margin: "0 0 3px 3px"}} viewBox="0 0 24 24">
                                <path fill="currentColor" d="M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H20A2 2 0 0 0 22 18V6A2 2 0 0 0 20 4M20 11H4V8H20Z" />
                            </svg>
                        </ToggleButton>
                        <ToggleButton variant="secondary" onClick={() => setPaymentType("Apple Pay")} style={paymentType !== "Apple Pay" ? {color: "#D3D3D3", fill: "#D3D3D3", flex: "1 0 33.33%", whiteSpace: "nowrap"} : {flex: "1 0 33.33%", whiteSpace: "nowrap"}}>
                            Apple Pay
                            <svg style={{width: "15px", margin: "0 0 3px 3px"}} viewBox="0 0 24 24">
                                <path fill="currentColor" d="M18.71,19.5C17.88,20.74 17,21.95 15.66,21.97C14.32,22 13.89,21.18 12.37,21.18C10.84,21.18 10.37,21.95 9.1,22C7.79,22.05 6.8,20.68 5.96,19.47C4.25,17 2.94,12.45 4.7,9.39C5.57,7.87 7.13,6.91 8.82,6.88C10.1,6.86 11.32,7.75 12.11,7.75C12.89,7.75 14.37,6.68 15.92,6.84C16.57,6.87 18.39,7.1 19.56,8.82C19.47,8.88 17.39,10.1 17.41,12.63C17.44,15.65 20.06,16.66 20.09,16.67C20.06,16.74 19.67,18.11 18.71,19.5M13,3.5C13.73,2.67 14.94,2.04 15.94,2C16.07,3.17 15.6,4.35 14.9,5.19C14.21,6.04 13.07,6.7 11.95,6.61C11.8,5.46 12.36,4.26 13,3.5Z" />
                            </svg>
                        </ToggleButton>
                        <ToggleButton variant="secondary" onClick={() => setPaymentType("Google Pay")} style={paymentType !== "Google Pay" ? {color: "#D3D3D3", fill: "#D3D3D3", flex: "1 0 33.33%", whiteSpace: "nowrap"} : {flex: "1 0 33.33%", whiteSpace: "nowrap"}}>
                            Google Pay
                            <svg style={{width: "15px", margin: "0 0 3px 3px"}} viewBox="0 0 24 24">
                                <path fill="currentColor" d="M3,20.5V3.5C3,2.91 3.34,2.39 3.84,2.15L13.69,12L3.84,21.85C3.34,21.6 3,21.09 3,20.5M16.81,15.12L6.05,21.34L14.54,12.85L16.81,15.12M20.16,10.81C20.5,11.08 20.75,11.5 20.75,12C20.75,12.5 20.53,12.9 20.18,13.18L17.89,14.5L15.39,12L17.89,9.5L20.16,10.81M6.05,2.66L16.81,8.88L14.54,11.15L6.05,2.66Z" />
                            </svg>
                        </ToggleButton>
                    </ButtonGroup>
                    {paymentType === "Card" &&
                        <div id="payment_form_container" />
                    }
                </div>
                <div className="coSubmitOrder" onClick={() => SubmitOrderHandler()}><p>SUBMIT ORDER</p></div>
            </div>
        </div>
    )
}

export default Checkout;